<template>
  <v-navigation-drawer
      v-model="drawer"
      right
      app
      style="background-color: var(--color-green)"
      dark
  >
    <v-img :aspect-ratio="16 / 7" :src="logo" class="mx-5 mt-2"/>
    <v-divider/>
    <v-list-item v-if="loggedUser != null">
      <v-list-item-content>
        <v-list-item-title>{{
            isAdmin
                ? loggedUser.role.name
                : loggedUser.building
                    ? loggedUser.building.name
                    : loggedUser.role.name
          }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon>
        <v-btn v-if="loggedUser" icon text @click="logOut">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-list-item-icon>
    </v-list-item>
    <v-divider/>
    <v-list dense nav>
      <v-list-item
          link
          v-for="(userFunction, ufIndex) in userFunctions.filter(
          (uf) => !uf.routes || uf.routes.length == 0
        )"
          :key="ufIndex"
          v-if="isUserPermitted(userFunction.permissions, userFunction.linkName)"
          :to="{ name: userFunction.linkName }"
          exact
      >
        <v-list-item-action>
          <v-icon>{{ userFunction.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ userFunction.content }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group
          v-for="(userFunction, ufIndex) in userFunctions.filter(
          (uf) => uf.routes && uf.routes.length != 0
        )"
          style="color: #fff !important; caret-color: #fff !important"
          v-if="isUserPermitted(userFunction.permissions)"
          :key="userFunction.key"
          :value="false"
          no-action
          sub-group
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ userFunction.content }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
            link
            exact
            v-for="(route, rIndex) in userFunction.routes"
            v-if="isUserPermitted(route.permissions)"
            :to="{ name: route.linkName }"
        >
          <v-list-item-action>
            <v-icon>{{ route.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ route.content }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-row>
          <v-col cols="2">
            <v-icon>mdi-email</v-icon>
          </v-col>
          <v-col cols="10" class="white--text"> Jed-SSA@moe.gov.sa</v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-icon>mdi-phone</v-icon>
          </v-col>
          <v-col cols="10" class="white--text"> 966126440048+</v-col>
        </v-row>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import logo from "@/assets/images/logo.png";

export default {
  name: "SideMenu",
  data() {
    return {
      logo,
      rules: {},
      dialog: false,
    };
  },
  computed: {
    ...mapGetters([
      "loggedUser",
      "isAdmin",
      "isSchoolBuilding",
      'isManagementBuilding',
      "isEmployee",
      "isServicesManager",
      "isServiceManager",
      "isManager",
      "isGeneralManager",
      "isBudgetRunner",
      "isTechnical",
      "isInquireManager",
      "isBFViewer",
      'isMeeter',
      "hasMeetingsPermission",
      "hasFinancialPermission",
    ]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set() {
      },
    },
    userFunctions() {
      return [
        {
          linkName: `News`,
          content: "الأخبار",
          icon: "mdi-newspaper",
          permissions: ["All"],
        },
        {
          linkName: `Home`,
          content: "الرئيسية",
          icon: "mdi-gauge",
          permissions: [
            "Admin",
            "Manager",
            "GeneralManager",
            "ServicesManager",
            "ServiceManager",
          ],
        },
        {
          linkName: `Users`,
          content: "المستخدمون",
          icon: "mdi-account",
          permissions: ["Admin", "Manager", "GeneralManager"],
        },
        {
          linkName: `OrderRejectionReasons`,
          content: "أسباب رفض الطلبات",
          icon: "mdi-text",
          permissions: ["Admin"],
        },
        {
          linkName: `OrderFinishReasons`,
          content: "أسباب انهاء الطلبات",
          icon: "mdi-text",
          permissions: ["Admin"],
        },
        {
          linkName: `OrderClosingReasons`,
          content: "أسباب اغلاق الطلبات",
          icon: "mdi-text",
          permissions: ["Admin"],
        },
        {
          linkName: `ReturnObjectCars`,
          content: "أنواع سيارات الرجيع",
          icon: "mdi-car",
          permissions: ["Admin"],
        },
        {
          linkName: `Orders`,
          content: "الطلبات",
          icon: "mdi-truck",
          permissions: [
            "Admin",
            "Employee",
            "ServicesManager",
            "ServiceManager",
            "Manager",
            "GeneralManager",
            "Technical",
          ],
        },
        {
          linkName: `Services`,
          content: "الخدمات",
          icon: "mdi-format-list-bulleted-square",
          permissions: ["Admin", "GeneralManager"],
        },
        {
          linkName: `Buildings`,
          content: "المباني",
          icon: "mdi-domain",
          permissions: ["Admin", "GeneralManager"],
        },
        {
          linkName: `Cars`,
          content: "السيارات",
          icon: "mdi-car",
          permissions: ["Admin", "Manager", "GeneralManager"],
        },
        // {
        //     linkName: `Guards`,
        //     content: 'حراس الأمن',
        //     icon: 'mdi-shield-account-outline',
        //     permissions: ['Admin','Manager','GeneralManager']
        // },
        {
          linkName: `Contractors`,
          content: "المتعهدين",
          icon: "mdi-account-multiple",
          permissions: ["Admin", "Manager", "GeneralManager"],
        },
        {
          linkName: `Objects`,
          content: "انواع الرجيع",
          icon: "mdi-arrow-u-left-top",
          permissions: ["Admin", "GeneralManager"],
        },
        {
          linkName: `Attachments`,
          content: "الارشيف",
          icon: "mdi-file-multiple",
          permissions: ["Admin", "Manager", "GeneralManager"],
        },
        {
          linkName: `FAQ`,
          content: "الأسئلة الشائعة",
          icon: "mdi-help-circle",
          permissions: ["Admin"],
        },
        {
          linkName: `InquireTitles`,
          content: "عناوين الاستفسارات",
          icon: "mdi-text",
          permissions: ["InquireManager", "Admin"],
        },
        {
          linkName: `Inquires`,
          content: "الاستفسارات",
          icon: "mdi-help",
          permissions: ["InquireManager", "Admin", "School", 'ServiceManager', 'ServicesManager'],
        },
        // {
        //     linkName: `Budgets`,
        //     content: 'الميزانيات',
        //     icon: 'mdi-currency-usd',
        //     permissions: ['Admin','GeneralManager','BudgetRunner']
        // },
        // {
        //     linkName: `Expenses`,
        //     content: 'الميزانية',
        //     icon: 'mdi-currency-usd',
        //     permissions: ['Employee']
        // }
        {
          key: 'MT',
          content: "الاجتماعات",
          icon: "mdi-sack",
          permissions: ["Admin", "Meeter", 'hasMeetingsPermission', 'ManagementBuilding'],
          routes: [
            {
              linkName: `MeetingHalls`,
              content: "القاعات",
              icon: "mdi-seat",
              permissions: ["Admin"],
            },
            {
              linkName: `MeetingOrders`,
              content: "طلبات الحجوزات",
              icon: "mdi-seat",
              permissions: ["Admin", 'Meeter', 'ManagementBuilding'],
            },
            {
              linkName: `Meetings`,
              content: "الاجتماعات",
              icon: "mdi-seat",
              permissions: ['hasMeetingsPermission', "Meeter", 'Admin'],
            },
          ],
        },
        {
          key: 'BF',
          content: "كشاف الميزانية",
          icon: "mdi-sack",
          permissions: ["Admin", "BFViewer", 'hasFinancialPermission'],
          routes: [
            {
              linkName: `FinancialFinderHome`,
              content: "الرئيسية",
              icon: "mdi-gauge",
              permissions: ["Admin", "BFViewer"],
            },
            {
              linkName: `administrations`,
              content: "الادارات",
              icon: "mdi-seat",
              permissions: ["Admin"],
            },
            {
              linkName: `Allocations`,
              content: "البنود",
              icon: "mdi-file",
              permissions: ["Admin"],
            },
            {
              linkName: `steps`,
              content: "سير العملية",
              icon: "mdi-octagon",
              permissions: ["Admin"],
            },
            {
              linkName: `operations`,
              content: "العمليات",
              icon: "mdi-origin",
              permissions: ["All"],
            },
          ],
        },
      ];
    },
  },
  methods: {
    ...mapActions(["logOut"]),
    isUserPermitted(permissions, linkName) {
      for (let p = 0; p < permissions.length; p++) {
        let permission = permissions[p];
        if (permission == "All") return true;
        if (permission == 'ManagementBuilding' && this.isManagementBuilding) return true;
        if (permission == 'hasMeetingsPermission' && this.hasMeetingsPermission) return true;
        if (permission == 'Meeter' && this.isMeeter) return true;
        if (permission == 'hasFinancialPermission' && this.hasFinancialPermission) return true;
        if (permission == "Admin" && this.isAdmin) return true;
        if (permission == "School" && this.isSchoolBuilding) return true;
        if (permission == "Employee" && this.isEmployee) return true;
        if (permission == "ServicesManager" && this.isServicesManager)
          return true;
        if (permission == "ServiceManager" && this.isServiceManager)
          return true;
        if (permission == "GeneralManager" && this.isGeneralManager)
          return true;
        if (permission == "BudgetRunner" && this.isBudgetRunner) return true;
        if (permission == "InquireManager" && this.isInquireManager)
          return true;
        if (permission == "Technical" && this.isTechnical) return true;
        if (permission == "BFViewer" && this.isBFViewer) return true;
        if (permission == "Manager" && this.isManager) {
          if (linkName == "Cars")
            return this.loggedUser.services.some((s) => s.id == 1);
          if (linkName == "Guards")
            return this.loggedUser.services.some((s) => s.id == 8);
          return true;
        }
      }
      return false;
    },
    closeSettings() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
