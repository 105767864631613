<template>
    <v-container>
        <v-card>
            <v-card-title class="flex-column justify-start align-start">
                <h3 class="text-h5">
                    الاجتماعات
                </h3>
                <h4 class="grey--text text-subtitle-1">
                    الرجاء اختيار يوم لرؤية الاجتماعات و معالجتها
                </h4>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-date-picker
                                locale="ar"
                                key="calendar"
                                v-model="selectedDate"
                                color="var(--color-gold)"
                                :events="meetingsDates"
                                event-color="var(--color-gold)"
                                @change="pickerChanged"
                                :picker-date.sync="pickerDate"
                        ></v-date-picker>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-list key="meetingsList" class="full-width">
                            <v-list-item-title v-if="selectedDate">
                                <v-btn
                                        class="mb-2 floatedBtn"
                                        v-if="hasMeetingsPermission || isAdmin"
                                        @click="openMeetingDialog(null)"
                                        icon
                                        text
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-list-item-title>
                            <v-list-item
                                    v-for="meeting in selectedDateMeetings"
                                    :key="meeting.id"
                            >
                                <v-list-item-avatar>
                                    <v-icon
                                            class="grey lighten-1"
                                            dark
                                    >
                                        mdi-seat
                                    </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title>{{meeting.createdBy == loggedUser.id?meeting.name:'-'}}
                                    </v-list-item-title>

                                    <v-list-item-subtitle>{{meeting.startTime}} -> {{meeting.endTime}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action  v-if="meeting.createdBy == loggedUser.id">
                                    <v-btn icon @click="openMeetingDialog(meeting)">
                                        <v-icon color="blue lighten-1">mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="openDeleteDialog(meeting)">
                                        <v-icon color="red lighten-1">mdi-delete</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog
                v-model="meetingDialog"
                max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    <v-spacer/>
                    <v-icon>mdi-seat</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="serviceForm" :lazy-validation="true">
                            <v-row>
                                <v-col
                                        cols="12"
                                >
                                    <v-text-field
                                            v-model="editedItem.name"
                                            label="الاسم"
                                            :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                >
                                    <v-menu
                                            ref="menu"
                                            v-model="startTimeMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="editedItem.startTime"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="editedItem.startTime"
                                                    label="وقت البداية"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                                v-if="startTimeMenu"
                                                v-model="editedItem.startTime"
                                                full-width
                                                @click:minute="$refs.menu.save(editedItem.startTime)"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                >
                                    <v-menu
                                            ref="endTimeMenu"
                                            v-model="endTimeMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="editedItem.endTime"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="editedItem.endTime"
                                                    label="وقت الانتهاء"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                                v-if="endTimeMenu"
                                                v-model="editedItem.endTime"
                                                full-width
                                                @click:minute="$refs.endTimeMenu.save(editedItem.endTime)"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue floatedBtn"
                            text
                            :loading="loading"
                            @click="close"
                    >
                        اغلاق
                    </v-btn>
                    <v-btn
                            color="blue floatedBtn"
                            text
                            :loading="loading"
                            @click="save"
                    >
                        حفظ
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue floatedBtn" text @click="closeDelete">لا</v-btn>
                    <v-btn color="blue floatedBtn" text @click="deleteItemConfirm">نعم</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex'
    import formValidation from "@/formValidation"

    export default {
        name: "meetingsList",
        computed: {
            ...mapGetters(['hasMeetingsPermission', 'loggedUser', 'isAdmin']),
            formTitle() {
                return this.editedIndex === -1 ? 'اجتماع جديد' : 'تعديل اجتماع'
            },
        },
        data() {
            return {
                rules: {},
                loading: false,
                selectedDate: null,
                startDate: null,
                endDate: null,
                meetingsDates: [],
                selectedDateMeetings: [],
                pickerDate: null,
                meetingDialog: false,
                dialogDelete: false,
                startTimeMenu: false,
                endTimeMenu: false,
                editedItem: {
                    id: -1,
                    name: '',
                    meetingDate: this.selectedDate,
                    startTime: '',
                    endTime: ''
                },
                defaultItem: {
                    id: -1,
                    name: '',
                    meetingDate: this.selectedDate,
                    startTime: '',
                    endTime: ''
                }
            }
        },
        methods: {
            pickerChanged(date) {
                this.selectedDate = date
                this.getMeetings(false)
            },
            openMeetingDialog(object) {
                if (object) {
                    this.editedItem = object
                } else {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedItem.meetingDate = this.selectedDate
                }
                this.meetingDialog = true
            },
            openDeleteDialog(object) {
                this.editedItem = object
                this.dialogDelete = true
            },
            async deleteItemConfirm() {
                await this.$deleteRequest('meeting', this.editedItem.id)
                this.getMeetings()
                this.closeDelete()
            },
            async getMeetings(forEvents) {
                if (forEvents)
                    this.meetingsDates = []
                let data = await this.$getRequest('meeting', '', {
                    startDate: forEvents ? this.startDate : undefined,
                    endDate: forEvents ? this.endDate : undefined,
                    date: !forEvents ? this.selectedDate : undefined
                })
                if (forEvents) {
                    this.meetingsDates = data.map(d => d.meetingDate)
                } else {
                    this.selectedDateMeetings = data
                }
            },
            close() {
                this.meetingDialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            async save() {
                try {
                    this.rules = formValidation.rules
                    if (this.$refs.serviceForm.validate()) {
                        this.loading = true
                        if (this.editedItem.id == -1) {
                            let data = await this.$postRequest('meeting', '', this.editedItem)
                            this.getMeetings(false)
                        } else {
                            let data = await this.$putRequest('meeting', this.editedItem.id, this.editedItem)
                            this.getMeetings(false)
                        }
                        this.close()
                    }
                } catch (error) {
                } finally {
                    this.loading = false
                }
            }
        },
        async mounted() {
            await this.getMeetings(true)
        },
        watch: {
            'pickerDate': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    const yearMonth = newVal.split('-')
                    this.startDate = this.$moment([yearMonth[0], yearMonth[1] - 1]).startOf('month').format('YYYY-MM-DD')
                    this.endDate = this.$moment([yearMonth[0], yearMonth[1] - 1]).endOf('month').format('YYYY-MM-DD')
                    this.getMeetings(true)
                }
            },
            meetingDialog(val) {
                val || this.close()
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
        }
    }
</script>

<style scoped>

</style>
