<template>
    <v-row>
        <v-col
                cols="2"
                v-for="(step, index) in steps"
                @click="stepClicked(step)"
                :class="{'clickableStep':isStepClickable(step)}"
        >
            <Step
                    :step="step"
                    :number="index+1"
                    :color="step.committee.color"
                    :active="step.id == activeStep.id"
            />
        </v-col>
        <v-col cols="12"><v-divider/></v-col>
        <v-col cols="12">
            <v-row>
                <v-col v-for="committee in committees">
                    <v-row class="flex-column text-center">
                        <v-col cols="12">
                            <v-icon :color="committee.color">mdi-octagon</v-icon>
                        </v-col>
                        <v-col cols="12">
                            {{committee.name}}
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import Step from "./Step";
    import {mapGetters} from 'vuex'

    export default {
        name: "StepsPath",
        components: {Step},
        props: {
            userCommittees: {
                required: true,
                type: Array,
                default: () => []
            },
            committees: {
                required: true,
                type: Array,
                default: () => []
            },
            steps: {
                required: true,
                type: Array,
                default: () => []
            },
            activeStep: {
                required: true,
                default: {}
            }
        },
        computed:{
            ...mapGetters(['isAdmin','isBFViewer'])
        },
        methods:{
            isStepClickable(step){
                return step.sort < this.activeStep.sort && (
                    (step.committee.id == this.activeStep.committee.id && this.userCommittees.some(uc=>uc.id == this.activeStep.committee.id))
                    || this.isAdmin || this.isBFViewer)
            },
            stepClicked(step){
                if(this.isStepClickable(step)){
                    this.$emit('stepClicked', step)
                }
            }
        }
    }
</script>

<style scoped>

    .clickableStep{
        cursor: pointer;
        transition-duration: 200ms;
    }
    .clickableStep:hover{
        transform: scale(1.05);
    }
</style>
