<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="attachments"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>الأرشيف</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-if="isAdmin"
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mb-2 floatedBtn"
                  v-bind="attrs"
                  v-on="on"
              >
                ملف جديد
              </v-btn>
            </template>
            <v-card :loading="loading">
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                <v-spacer/>
                <v-icon>mdi-file</v-icon>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="attachmentForm" :lazy-validation="true">
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-file-input
                            v-model="file"
                            label="الملف"
                            :rules="[rules.required]"
                        ></v-file-input>
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-autocomplete
                            :items="managers"
                            item-text="username"
                            item-value="id"
                            label="المدراء"
                            multiple
                            :rules="[rules.required]"
                            v-model="editedItem.usersIds"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-textarea
                            v-model="editedItem.description"
                            label="الوصف"
                            rows="2"
                            :rules="[rules.required]"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue floatedBtn"
                    text
                    :loading="loading"
                    @click="close"
                >
                  اغلاق
                </v-btn>
                <v-btn
                    color="blue floatedBtn"
                    text
                    :loading="loading"
                    @click="save"
                >
                  حفظ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text @click="closeDelete">لا</v-btn>
                <v-btn color="blue floatedBtn" text @click="deleteItemConfirm">نعم</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="blue" text link target="_blank" :href="`${$baseUrl}downloadAttachments/${item.filePath}`">
          <v-icon small>
            mdi-download
          </v-icon>
        </v-btn>
        <v-icon
            v-if="isAdmin"
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ $moment(item.created_at).format('yyyy/MM/DD') }}
      </template>
      <template v-slot:item.users="{ item }">
        {{ item.users.map(u => u.username).join(' , ') }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import formValidation from "@/formValidation"
import {mapGetters} from 'vuex'

export default {
  name: "AttachmentsList",
  data() {
    return {
      rules: {},
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'الوصف',
          align: 'start',
          sortable: false,
          value: 'description',
        },
        {
          text: 'المدراء',
          align: 'start',
          sortable: false,
          value: 'users',
        },
        {
          text: 'التاريخ',
          align: 'start',
          sortable: false,
          value: 'created_at',
        },
        {
          text: 'أدوات',
          align: 'start',
          sortable: false,
          value: 'actions',
        }
      ],
      attachments: [],
      managers: [],
      editedIndex: -1,
      editedItem: {
        id: -1,
        description: '',
        usersIds: null
      },
      defaultItem: {
        id: -1,
        description: '',
        usersIds: null
      },
      file: null,
      loading: false
    }
  },

  computed: {
    ...mapGetters(['loggedUser', 'isAdmin']),
    formTitle() {
      return this.editedIndex === -1 ? 'ملف جديد' : 'تعديل ملف'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.attachments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.attachments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      await this.$deleteRequest('attachment', this.editedItem.id)
      this.attachments.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.file = null
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      try {
        this.rules = formValidation.rules
        if (this.$refs.attachmentForm.validate()) {
          this.loading = true
          const {description, usersIds} = this.editedItem
          let form = new FormData()
          form.append('description', description)
          form.append('usersIds', usersIds)
          form.append('file', this.file)
          if (this.editedItem.id == -1) {
            let data = await this.$postRequest('attachment', '', form)
          } else {
            let data = await this.$putRequest('attachment', this.editedItem.id, form)
          }
          this.notify({textType: this.snackBarTextType.saveSuccessfully});
          this.close()
        }
      } catch (error) {
        this.notify({textType: this.snackBarTextType.saveError});
      } finally {
        this.loading = false
      }
    },
    async getManagers() {
      let data = await this.$getRequest('users', '', {
        roleId: 10000
      })
      this.managers = data
    },
    async getAttachments() {
      this.attachments = await this.$getRequest('attachment', '')
    }
  },
  async mounted() {
    if (!this.isAdmin) {
      this.headers.splice(2, 1)
    }
    await this.getManagers()
    await this.getAttachments()
  }
}
</script>

<style scoped>

</style>
