<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="operations"
        :loading="fetching"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>العمليات</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              v-if="hasFinancialPermission || isAdmin || isBFViewer"
              fullscreen
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-if="!isAppInViewMode"
                  class="mb-2 floatedBtn"
                  v-bind="attrs"
                  v-on="on"
              >
                عملية جديدة
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                <v-spacer/>
                <v-icon>mdi-origin</v-icon>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="operationForm" :lazy-validation="true">
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-autocomplete
                            label="البند"
                            :items="allocations"
                            item-text="name"
                            item-value="id"
                            v-model="editedItem.allocationId"
                            :rules="[rules.required]"
                            :disabled="viewMode"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            :value="selectedAllocation.abv"
                            label="رقم البند"
                            disabled
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.name"
                            label="اسم العملية"
                            :rules="[rules.required]"
                            :disabled="viewMode"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          v-if="!isAppInViewMode && canLoggedUserProcessCurrentStep && viewMode && !clickedStep"
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-if="selectedOperationCurrentStep.valueType == 'TEXT'"
                            v-model="currentStepValue"
                            :label="selectedOperationCurrentStep.name"
                            :rules="[rules.required]"
                        ></v-text-field>
                        <DatePicker
                            v-if="selectedOperationCurrentStep.valueType == 'DATE'"
                            :label="selectedOperationCurrentStep.name"
                            v-model="currentStepValue"
                            :key="selectedOperationCurrentStep.id"
                        />
                      </v-col>
                      <v-col
                          v-if="clickedStep"
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-if="clickedStep.valueType == 'TEXT'"
                            v-model="currentStepValue"
                            :label="clickedStep.name"
                            :disabled="isAppInViewMode || isBFViewer"
                            :rules="[rules.required]"
                        ></v-text-field>
                        <DatePicker
                            v-if="clickedStep.valueType == 'DATE'"
                            :label="clickedStep.name"
                            :disabled="isAppInViewMode || isBFViewer"
                            v-model="currentStepValue"
                            :key="clickedStep.id"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                  <div style="width: 100%">
                    <StepsPath
                        v-if="viewMode"
                        :activeStep="selectedOperationCurrentStep"
                        :steps="steps"
                        :committees="committees"
                        :userCommittees="loggedUserCommittees"
                        @stepClicked="stepClicked"
                    />
                  </div>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue floatedBtn"
                    text
                    :loading="loading"
                    @click="close"
                >
                  اغلاق
                </v-btn>
                <v-btn
                    v-if="!isAppInViewMode && editedItem.id == -1"
                    color="blue floatedBtn"
                    text
                    :loading="loading"
                    @click="save"
                >
                  حفظ
                </v-btn>
                <v-btn
                    v-if="!isAppInViewMode && !isBFViewer && !clickedStep && editedItem.id != -1 && canLoggedUserProcessCurrentStep"
                    color="blue floatedBtn"
                    text
                    :loading="loading"
                    @click="getToNextStep"
                >
                  الانتقال الى الخطوة التالية
                </v-btn>
                <v-btn
                    v-if="!isAppInViewMode && clickedStep && !isBFViewer"
                    color="blue floatedBtn"
                    text
                    :loading="loading"
                    @click="editClickedStep"
                >
                  تعديل الخطوة
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text @click="closeDelete">لا</v-btn>
                <v-btn color="blue floatedBtn" text @click="deleteItemConfirm">نعم</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.id="{ item }">
        <h4 style="margin-right: 0.4rem">{{ item.id }}</h4>
        <div>
          <v-icon v-if="item.isCompleted == 1" color="green">mdi-check-circle</v-icon>
          <v-icon v-if="item.isCompleted == 0 && shouldDisplayWarning(item)" :color="warningColor(item)">
            mdi-alert-outline
          </v-icon>
        </div>
      </template>
      <template v-slot:item.faresNumber="{ item }">
        {{ getFaresNumber(item) }}
      </template>
      <template v-slot:item.faresDate="{ item }">
        {{ getFaresDate(item) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            v-if="hasFinancialPermission || isAdmin || isGeneralManager || isBFViewer"
            small
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
            v-if="isAdmin && !isAppInViewMode"
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.administrations="{item}">
        {{ item.allocation.administrations.map(a => a.name).join(', ') }}
      </template>
      <template v-slot:item.committeeName="{ item }">
        <div class="coloredDiv"
             :style="`width:100%;background-color:${item.current_step.committee.color}`">
          {{ item.current_step.committee.name }}
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import formValidation from "@/formValidation"
import CircularProgress from "@/components/expenses/CircularProgress"
import {mapGetters} from 'vuex'
import DatePicker from "@/components/common/DatePicker";
import StepsPath from "@/components/operation/StepsPath";

export default {
  name: "operationsList",
  components: {DatePicker, StepsPath},
  data() {
    return {
      rules: formValidation.rules,
      dialog: false,
      dialogDelete: false,
      loading: false,
      fetching: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'اسم العملية',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'الرقم في برنامج فارس',
          align: 'start',
          sortable: false,
          value: 'faresNumber',
        },
        {
          text: 'تاريخ الطرح في فارس',
          align: 'start',
          sortable: false,
          value: 'faresDate',
        },
        {
          text: 'اسم البند',
          align: 'start',
          sortable: false,
          value: 'allocation.name',
        },
        {
          text: 'رقم البند',
          align: 'start',
          sortable: false,
          value: 'allocation.abv',
        },
        {
          text: 'الادارة المسؤولة',
          align: 'start',
          sortable: false,
          value: 'administrations',
        },
        {
          text: 'سير العملية',
          align: 'start',
          sortable: false,
          value: 'committeeName',
        },
        {
          text: 'أدوات',
          align: 'start',
          sortable: false,
          value: 'actions',
        }
      ],
      operations: [],
      allocations: [],
      steps: [],
      committees: [],
      editedIndex: -1,
      editedItem: {
        id: -1,
        name: '',
        allocationId: null,
        isCompleted: 0
      },
      defaultItem: {
        id: -1,
        name: '',
        allocationId: null,
        isCompleted: 0
      },
      viewMode: false,
      currentStepValue: '',
      clickedStep: null
    }
  },

  computed: {
    ...mapGetters([
      'loggedUser',
      'isAdmin',
      'isBFViewer',
      'isGeneralManager',
      'hasFinancialPermission',
      'isAppInViewMode'
    ]),
    formTitle() {
      return this.editedIndex === -1 ? 'عملية جديدة' : ''
    },
    loggedUserCommittees() {
      return this.loggedUser.committees
    },
    selectedAllocation() {
      let allocation = this.allocations.find(a => a.id == this.editedItem.allocationId)
      return allocation ? allocation : {}
    },
    selectedOperationCurrentStep() {
      return this.editedItem.current_step
    },
    isOperationIsLoggedUserCommittees() {
      return this.loggedUserCommittees.some(c => c.id == this.selectedOperationCurrentStep.committee.id)
    },
    canLoggedUserProcessCurrentStep() {
      if (!this.selectedOperationCurrentStep) return false
      if (this.editedItem.isCompleted == 1) return false
      if (this.isAdmin) return true
      return this.isOperationIsLoggedUserCommittees
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    stepClicked(step) {
      const stepAction = this.editedItem.actions.find(a => a.stepId == step.id)
      if (stepAction) {
        this.currentStepValue = stepAction.value
        this.clickedStep = Object.assign({}, step)
      }
    },
    finalTimeForStep(operation) {
      const lastStep = this.steps.find(s => s.sort == operation.current_step.sort - 1)
      let lastStepAction = null
      if (lastStep)
        lastStepAction = operation.actions.find(a => a.stepId == lastStep.id)
      return operation.current_step.sort == 1 ?
          this.$moment(operation.created_at).add(operation.current_step.daysDuration, 'd') :
          this.$moment(lastStepAction.created_at).add(operation.current_step.daysDuration, 'd')
    },
    shouldDisplayWarning(operation) {
      let finalDate = this.finalTimeForStep(operation)
      let today = this.$moment().add(3, 'h')
      return today.isAfter(finalDate) ||
          today.isAfter(finalDate.add(operation.current_step.daysDuration * -12, 'h'))
    },
    warningColor(operation) {
      let color = 'grey'
      if (this.shouldDisplayWarning(operation)) {
        let finalDate = this.finalTimeForStep(operation)
        let today = this.$moment().add(3, 'h')
        if (today.isAfter(finalDate)) color = 'red'
        else if (today.isAfter(finalDate.add(operation.current_step.daysDuration * -12, 'h'))) color = 'yellow'
      }
      return color
    },
    getFaresNumber(operation) {
      let faresNumberAction = operation.actions.find(a => a.stepId == 1)
      return faresNumberAction ? faresNumberAction.value : '---'
    },
    getFaresDate(operation) {
      let faresDateAction = operation.actions.find(a => a.stepId == 2)
      return faresDateAction ? this.$moment(faresDateAction.value).format('yyyy/MM/DD') : '---'
    },
    async getAnOperation(id) {
      let data = await this.$getRequest('operation', `single/${id}`)
      return data
    },
    async editItem(item) {
      this.editedIndex = this.operations.indexOf(item)
      this.editedItem = Object.assign({}, await this.getAnOperation(item.id))
      this.viewMode = true
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.operations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      await this.$deleteRequest('operation', this.editedItem.id)
      this.operations.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.viewMode = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.setOperations()
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      try {
        if (this.$refs.operationForm.validate()) {
          this.loading = true
          const {name, allocationId} = this.editedItem
          if (this.editedItem.id == -1) {
            let data = await this.$postRequest('operation', '', {name, allocationId})
          } else {
            let data = await this.$putRequest('operation', this.editedItem.id, {name, allocationId})
          }
          this.setOperations()
          this.notify({textType: this.snackBarTextType.saveSuccessfully});
          this.close()
        }
      } catch (error) {
        this.notify({textType: this.snackBarTextType.saveError});
      } finally {
        this.loading = false
      }
    },
    async getToNextStep() {
      try {
        if (await this.$refs.operationForm.validate()) {
          this.loading = true
          await this.$postRequest('operation', 'proceedToNextStep', {
            id: this.editedItem.id,
            value: this.currentStepValue
          })
          this.currentStepValue = ''
          this.editedItem = Object.assign({}, await this.getAnOperation(this.editedItem.id))
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.notify({text: error.response.data.msg});
        }
      } finally {
        this.loading = false
      }
    },
    async editClickedStep() {
      try {
        if (await this.$refs.operationForm.validate()) {
          this.loading = true
          await this.$postRequest('operation', 'updateStepAction', {
            stepId: this.clickedStep.id,
            operationId: this.editedItem.id,
            value: this.currentStepValue
          })
          this.currentStepValue = ''
          this.clickedStep = null
          this.editedItem = Object.assign({}, await this.getAnOperation(this.editedItem.id))
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.notify({text: error.response.data.msg});
        }
      } finally {
        this.loading = false
      }
    },
    async setOperations() {
      try {
        this.fetching = true
        this.operations = []
        let operations = await this.$getRequest('operation', '')
        this.operations = operations
      } catch (error) {

      } finally {
        this.fetching = false
      }
    }
  },
  async mounted() {
    this.setOperations()
    this.allocations = await this.$getRequest('allocation', '')
    this.committees = await this.$getRequest('committee', '')
    this.steps = await this.$getRequest('step', '')
  }
}
</script>

<style scoped>
.coloredDiv {
  min-width: 40%;
  padding: 3px;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
}
</style>
