<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="returnObjects"
        :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>انواع الرجيع</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-text-field
              class="mt-5"
              label="البحث"
              append-icon="mdi-magnify"
              @click:append="search"
              v-model="filters.q"
              @keydown.enter="search"
              dense
              solo
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mb-2 floatedBtn"
                  v-bind="attrs"
                  v-on="on"
                  v-if="!isGeneralManager"
              >
                نوع جديد
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                <v-spacer/>
                <v-icon>mdi-arrow-u-left-top</v-icon>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="returnObjectForm" :lazy-validation="true">
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.name"
                            label="الاسم"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.abv"
                            label="الرمز"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="close"
                    :loading="loading"
                >
                  اغلاق
                </v-btn>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="save"
                    :loading="loading"
                >
                  حفظ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text @click="closeDelete">لا</v-btn>
                <v-btn color="blue floatedBtn" text @click="deleteItemConfirm">نعم</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-if="!isGeneralManager"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
            v-if="!isGeneralManager"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import formValidation from "@/formValidation"
import {mapGetters} from 'vuex'

export default {
  name: "returnObjectsList",
  data() {
    return {
      rules: {},
      dialog: false,
      dialogDelete: false,
      loading: false,
      headers: [
        {
          text: 'الاسم',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'الرمز',
          align: 'start',
          sortable: false,
          value: 'abv',
        },
        {
          text: 'أدوات',
          align: 'start',
          sortable: false,
          value: 'actions',
        }
      ],
      returnObjects: [],
      editedIndex: -1,
      filters: {
        q: ''
      },
      editedItem: {
        id: -1,
        name: '',
        abv: '',
      },
      defaultItem: {
        id: -1,
        name: '',
        abv: '',
      },
    }
  },

  computed: {
    ...mapGetters([
      'isGeneralManager'
    ]),
    formTitle() {
      return this.editedIndex === -1 ? 'نوع جديد' : 'تعديل نوع'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.returnObjects.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.returnObjects.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      await this.$deleteRequest('returnObject', this.editedItem.id)
      this.returnObjects.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      try{
        this.rules = formValidation.rules
        if (this.$refs.returnObjectForm.validate()) {
          this.loading = true
          if (this.editedItem.id == -1) {
            let data = await this.$postRequest('returnObject', '', {name: this.editedItem.name, abv: this.editedItem.abv})
            this.returnObjects.push(data)
          } else {
            let data = await this.$putRequest('returnObject', this.editedItem.id, {
              name: this.editedItem.name,
              abv: this.editedItem.abv
            })
            Object.assign(this.returnObjects[this.editedIndex], data)
          }
          this.notify({textType: this.snackBarTextType.saveSuccessfully});
          this.close()
        }
      }
      catch(error){
        this.notify({textType: this.snackBarTextType.saveError});
      }
      finally {
        this.loading = false
      }
    },
    async setReturnObjects() {
      try {
        this.loading = true
        let returnObjects = await this.$getRequest('returnObject', '', {
          q: this.filters.q,
        })
        this.returnObjects = returnObjects
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    search() {
      this.setReturnObjects()
    },
  },
  mounted() {
    this.setReturnObjects()
  }
}
</script>

<style scoped>

</style>
