<template>
  <v-container fill-height>
    <v-row class="justify-center align-center">
      <v-col col="12" md="6">
        <v-img :src="logo"></v-img>
      </v-col>
      <v-col cols="12" md="6">
        <v-card width="90%" elevation="2">
          <v-card-text>
            <v-container class="d-flex flex-column" style=";justify-content: end;">
              <v-container fluid>
                <v-row style="width: 80%;">
                  <v-col cols="12">
                    <h1 class="text-sm-h6 text-md-h4"
                        style="white-space: pre-line; color:var(--color-green);  font-family: 'GESS',roboto,sans-serif !important;line-height: initial">
                      {{ 'مرحبا بكم في بوابة\nالإلكترونية لقطاع الخدمات' }}</h1>
                  </v-col>
                  <v-col cols="12" dense>
                    <v-text-field v-model="username"
                                  label="اسم المستخدم"
                                  autocomplete="username"
                                  @keypress.enter="keyPressedUsername()"
                                  small
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field ref="passwordInput"
                                  type="password"
                                  v-model="password"
                                  label="الرمز السري"
                                  @keypress.enter="keyPressedPassword()"
                                  autocomplete="new-password"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn class="signInBtn" @click="signIn" v-ripple="{ class: `goldRipple` }">تسجيل الدخول</v-btn>
                  </v-col>
                  <v-col>
                    <v-btn class="signInBtn" style="width:fit-content" @click="showForgotMyPassword"
                           v-ripple="{ class: `goldRipple` }">نسيت كلمة المرور
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <v-dialog v-model="forgotMyPasswordDialog" max-width="30%">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">نسيت كلمة المرور</span>
                    <v-spacer/>
                    <v-icon>mdi-key</v-icon>
                  </v-card-title>
                  <v-card-text>
                    <h4>يرجى التواصل على الايميل Jed-SSA@moe.gov.sa</h4>
                    <h4>او ارسال رسالة على الواتس على الرقم الخاص بالبوابة الإلكترونية لقطاع الخدمات 0126440048</h4>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="hideForgotMyPassword"
                    >
                      اغلاق
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import logo from '@/assets/images/logo.png'

export default {
  name: "SignIn",
  data() {
    return {
      logo,
      username: '',
      password: '',
      showPassword: false,
      forgotMyPasswordDialog: false
    }
  },
  computed: {
    ...mapGetters(['loggedUser'])
  },
  methods: {
    keyPressedUsername() {
      this.$refs.passwordInput.focus()
    },
    keyPressedPassword() {
      if (event.key == 'Enter') this.signIn()
    },
    async signIn() {
      await this.$store.dispatch('signIn', {
        username: this.username,
        password: this.password,
        callback: this.goToHome,
        errorCallback: (error) => {
          if (error.response.status == 404) {
            this.notify({text: error.response.data});
          }
        }
      })
    },
    goToHome() {
      this.$router.replace({name: 'Home'})
    },
    showForgotMyPassword() {
      this.forgotMyPasswordDialog = true
    },
    hideForgotMyPassword() {
      this.forgotMyPasswordDialog = false
    }
  }
}
</script>

<style scoped>
.signInBtn {
  width: 100%;
  border-radius: 32px;
  color: var(--color-green);
  justify-content: start;
  height: 64px !important;
  box-shadow: var(--color-green) 0px 1px 1px;
}

</style>
