<template>
    <v-container>
        <v-row class="mt-6">
            <v-col
                    cols="12"
                    md="4"
            >
                <v-btn icon text @click="downloadOperationsReport" :disabled="dates.length == 1" color="primary"><v-icon>mdi-printer</v-icon></v-btn>
                <br>
                <v-date-picker
                        v-model="dates"
                        color="var(--color-gold)"
                        range
                ></v-date-picker>
                <v-row class="mt-6">
                    <v-col cols="9">
                        <v-text-field
                                v-model="dateRangeText"
                                label="مجال التواريخ"
                                prepend-icon="mdi-calendar"
                                readonly
                        ></v-text-field>
                    </v-col>
                    <v-col
                            cols="12"
                            sm="6"
                            md="6"
                    >
                        <v-select
                                v-model="filters.allocationId"
                                label="البند"
                                :items="allocations"
                        ></v-select>
                    </v-col>
                    <v-col
                            cols="12"
                            sm="6"
                            md="6"
                    >
                        <v-select
                                v-model="filters.operationId"
                                label="العملية"
                                :items="operations"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import moment from 'moment'
    import {mapGetters} from 'vuex'
    import ReportCard from "@/components/Home/ReportCard";

    export default {
        name: "FinancialFinderHome",
        components:{ReportCard},
        data: () => ({
            dates: [],
            allocations: [],
            operations: [],
            allocationsReports:[],
            filters:{
                allocationId: -1,
                operationId: -1
            }
        }),
        computed: {
            ...mapGetters(['isEmployee','loggedUser','isAdmin','isGeneralManager']),
            dateRangeText () {
                return this.dates.join(' ~ ')
            }
        },
        methods:{
            async downloadOperationsReport(){
                window.open(`${this.$baseUrl}generate-operations-report?allocationId=${this.filters.allocationId}&uikd=${this.loggedUser.id}&operationId=${this.filters.operationId}&startDate=${this.dates.length > 0?this.dates[0]:undefined}&endDate=${this.dates.length > 1?moment(this.dates[1]).add(1,'day').format('yyyy-MM-DD'):undefined}`)
            },
            async getAllocations() {
                let allocations = await this.$getRequest('allocation', '')
                this.allocations = allocations.map(s=>{
                    return {
                        text: s.name,
                        value: s.id
                    }
                })
                this.allocations.unshift({
                    text:'الكل',
                    value:-1
                })
            },
            async getOperations() {
                let operations = await this.$getRequest('operation', '')
                this.operations = operations.map(s=>{
                    return {
                        text: s.name,
                        value: s.id
                    }
                })
                this.operations.unshift({
                    text:'الكل',
                    value:-1
                })
            }
        },
        mounted(){
            this.getAllocations()
            this.getOperations()
        }
    }
</script>

<style scoped>

</style>
