<template>
  <v-app-bar
    app
    style="background-color: var(--color-green); height: 76px"
    dark
    elevation="1"
  >
    <v-app-bar-nav-icon
      v-if="loggedUser"
      @click="switchDrawer()"
    ></v-app-bar-nav-icon>
    <NotificationsMenu v-if="loggedUser" />
    <v-icon class="mx-3" @click="goToFAQ">mdi-help</v-icon>
    <v-spacer />
    <div class="d-flex flex-column align-end">
      <v-img
        class="mt-4"
        :src="headerLogo"
        max-height="90"
        max-width="90"
        contain
      ></v-img>
      <h5>الإدارة العامة للتعليم بمحافظة جدة</h5>
    </div>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NotificationsMenu from "@/components/NotificationsMenu";
import headerLogo from "@/assets/images/headerLogo.png";
import InquireForm from "@/components/Order/Inquire/InquireForm";

export default {
  name: "AppHeader",
  components: { NotificationsMenu, InquireForm },
  computed: {
    ...mapGetters(["loggedUser", "isAdmin", "isEmployee", "isSchoolBuilding"]),
  },
  data() {
    return {
      headerLogo,
    };
  },
  methods: {
    ...mapActions(["switchDrawer"]),
    goToFAQ() {
      this.$router.push({ name: "FAQ" });
    }
  },
};
</script>

<style scoped>
div.v-toolbar__content {
  align-items: center !important;
}

.logo {
  transition: transform 200ms ease-in-out;
  cursor: pointer;
}

.logo:hover {
  transform: scaleX(1.2);
}
</style>
