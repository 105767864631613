<template>
    <v-container>
        <v-data-table
                :headers="headers"
                :items="guards"
                :loading="loading"
        >
            <template v-slot:top>
                <v-toolbar
                        flat
                >
                    <v-toolbar-title>حراس الأمن</v-toolbar-title>
                    <v-divider
                            class="mx-4"
                            inset
                            vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog
                            v-model="dialog"
                            max-width="500px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    class="mb-2 floatedBtn"
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if="!isGeneralManager"
                                    :loading="loading"
                            >
                                حارس جديد
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                                <v-spacer/>
                                <v-icon>mdi-guard</v-icon>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-form ref="guardForm" :lazy-validation="true">
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                            >
                                                <v-autocomplete
                                                        v-model="editedItem.buildingId"
                                                        label="المبنى"
                                                        :rules="[rules.required]"
                                                        :items="buildings"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.name"
                                                        label="الاسم"
                                                        :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.contactNumber"
                                                        label="رقم التواصل"
                                                        :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="blue floatedBtn"
                                        text
                                        @click="close"
                                        :loading="loading"
                                >
                                    اغلاق
                                </v-btn>
                                <v-btn
                                        color="blue floatedBtn"
                                        text
                                        @click="save"
                                        :loading="loading"
                                >
                                    حفظ
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue floatedBtn" :loading="loading" text @click="closeDelete">لا</v-btn>
                                <v-btn color="blue floatedBtn" :loading="loading" text @click="deleteItemConfirm">نعم</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                        v-if="!isGeneralManager"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                        small
                        @click="deleteItem(item)"
                        v-if="!isGeneralManager"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import formValidation from "@/formValidation"
    import {mapGetters} from 'vuex'

    export default {
        name: "guardsList",
        data() {
            return {
                rules: {},
                dialog: false,
                dialogDelete: false,
                buildings: [],
                headers: [
                    {
                        text: 'الاسم',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {
                        text: 'الرقم',
                        align: 'start',
                        sortable: false,
                        value: 'contactNumber',
                    },
                    {
                        text: 'المبنى',
                        align: 'start',
                        sortable: false,
                        value: 'building.name',
                    },
                    {
                        text: 'أدوات',
                        align: 'start',
                        sortable: false,
                        value: 'actions',
                    }
                ],
                guards: [],
                loading: false,
                editedIndex: -1,
                editedItem: {
                    id: -1,
                    name: '',
                    contactNumber: '',
                    buildingId: null
                },
                defaultItem: {
                    id: -1,
                    name: '',
                    contactNumber: '',
                    buildingId: null
                },
            }
        },

        computed: {
            ...mapGetters([
                'isGeneralManager'
            ]),
            formTitle() {
                return this.editedIndex === -1 ? 'حارس جديد' : 'تعديل حارس'
            },
        },
        watch: {
            dialog(val) {
                val || this.close()
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
        },
        methods: {
            editItem(item) {
                this.editedIndex = this.guards.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem(item) {
                this.editedIndex = this.guards.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            async deleteItemConfirm() {
               try{
                   this.loading = true
                   await this.$deleteRequest('guard', this.editedItem.id)
                   this.guards.splice(this.editedIndex, 1)
                   this.closeDelete()
               }
               catch(error){}
               finally {
                   this.loading = false
               }
            },

            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            async save() {
              try{
                  this.rules = formValidation.rules
                  if (this.$refs.guardForm.validate()) {
                      this.loading = true
                      const {
                          name,
                          contactNumber,
                          buildingId
                      } = this.editedItem
                      const form = {
                          name,
                          contactNumber,
                          buildingId
                      }
                      if (this.editedItem.id == -1) {
                          let data = await this.$postRequest('guard', '', form)
                          this.getGuards()
                      } else {
                          let data = await this.$putRequest('guard', this.editedItem.id, form)
                          this.getGuards()
                      }
                      this.close()
                  }
              }
              catch(error){}
              finally {
                  this.loading = false
              }
            },
            async getGuards() {
                try{
                    this.loading = true
                    let guards = await this.$getRequest('guard', '')
                    this.guards = guards
                }
                catch(error){}
                finally {
                    this.loading = false
                }
            },
            async getBuildings() {
                let buildings = await this.$getRequest('building', '')
                this.buildings = buildings.map(s => {
                    return {
                        text: s.name,
                        value: s.id
                    }
                })
            },
        },
        async mounted() {
            this.getGuards()
            this.getBuildings()
        }
    }
</script>

<style scoped>

</style>
