import Vue from 'vue'
import vueInstance from '@/main.js'
import VueRouter from 'vue-router'
import store from '@/store'
import SignIn from "@/views/SignIn"
import Home from "@/views/Home"
import servicesList from "@/views/services/servicesList"
import buildingsList from "@/views/buildings/buildingsList"
import carsList from "@/views/cars/carsList"
import contractorsList from "@/views/contractors/contractorsList"
import objectsList from "@/views/objects/objectsList"
import ordersList from "@/views/ordersList/ordersList"
import InsertContactNumber from "@/views/InsertContactNumber"
import Users from "@/views/users/Users"
import AttachmentsList from "@/views/Attachment/AttachmentsList"
import FAQList from "@/views/FAQ/FAQList";
import InquiriesList from "@/views/Inquire/InquiriesList";
import InquireTitlesList from "@/views/Inquire/InquireTitlesList.vue";
import budgetsList from "@/views/budgets/budgetsList";
import expensesList from "@/views/expenses/expensesList";
import guardsList from "@/views/guards/guardsList";
import meetingsList from "@/views/Meetings/meetingsList";
import meetingHallsList from "@/views/Meetings/meetingHallsList";
import allocationsList from "@/views/allocations/allocationsList";
import administrationsList from "@/views/administrations/administrationsList";
import stepsList from "@/views/steps/stepsList";
import operationsList from "@/views/operations/operationsList";
import FinancialFinderHome from "@/views/FinancialFinderHome";
import newsList from "@/views/NewsList/newsList";
import meetingOrdersList from "@/views/Meetings/meetingOrdersList.vue";
import OrderRejectionReasonsList from "@/views/ordersList/OrderRejectionReasonsList.vue";
import ReturnObjectCarsList from "@/views/ordersList/ReturnObjectCarsList.vue";
import OrderFinishReasonsList from "@/views/ordersList/OrderFinishReasonsList.vue";
import OrderClosingReasonsList from "@/views/ordersList/OrderClosingReasonsList.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/SignIn',
        name: 'SignIn',
        component: SignIn
    },
    {
        path: '/',
        name: 'News',
        component: newsList
    },
    {
        path: '/Home',
        name: 'Home',
        component: Home
    },
    {
        path: '/FinancialFinderHome',
        name: 'FinancialFinderHome',
        component: FinancialFinderHome
    },
    {
        path: '/Services',
        name: 'Services',
        component: servicesList
    },
    {
        path: '/Buildings',
        name: 'Buildings',
        component: buildingsList
    },
    {
        path: '/Cars',
        name: 'Cars',
        component: carsList
    },
    {
        path: '/Contractors',
        name: 'Contractors',
        component: contractorsList
    },
    {
        path: '/Objects',
        name: 'Objects',
        component: objectsList
    },
    {
        path: '/Orders',
        name: 'Orders',
        component: ordersList
    },
    {
        path: '/Contact',
        name: 'ContactNumber',
        component: InsertContactNumber
    },
    {
        path: '/Users',
        name: 'Users',
        component: Users
    },
    {
        path: '/Attachments',
        name: 'Attachments',
        component: AttachmentsList
    },
    {
        path: '/FAQ',
        name: 'FAQ',
        component: FAQList
    },
    {
        path: '/Inquires',
        name: 'Inquires',
        component: InquiriesList
    },
    {
        path: '/InquireTitles',
        name: 'InquireTitles',
        component: InquireTitlesList
    },
    {
        path: '/OrderRejectionReasons',
        name: 'OrderRejectionReasons',
        component: OrderRejectionReasonsList
    },
    {
        path: '/OrderFinishReasons',
        name: 'OrderFinishReasons',
        component: OrderFinishReasonsList
    },
    {
        path: '/OrderClosingReasons',
        name: 'OrderClosingReasons',
        component: OrderClosingReasonsList
    },
    {
        path: '/ReturnObjectCars',
        name: 'ReturnObjectCars',
        component: ReturnObjectCarsList
    },
    {
        path: '/Budgets',
        name: 'Budgets',
        component: budgetsList
    },
    {
        path: '/Expenses',
        name: 'Expenses',
        component: expensesList
    },
    {
        path: '/Guards',
        name: 'Guards',
        component: guardsList
    },
    {
        path: '/Meetings',
        name: 'Meetings',
        component: meetingsList
    },
    {
        path: '/MeetingOrders',
        name: 'MeetingOrders',
        component: meetingOrdersList
    },
    {
        path: '/MeetingHallsList',
        name: 'MeetingHalls',
        component: meetingHallsList
    },
    {
        path: '/Allocations',
        name: 'Allocations',
        component: allocationsList
    },
    {
        path: '/administrations',
        name: 'administrations',
        component: administrationsList
    },
    {
        path: '/steps',
        name: 'steps',
        component: stepsList
    },
    {
        path: '/operations',
        name: 'operations',
        component: operationsList
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    let loggedUser = localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE_USER_KEY)
    if (to.name == 'SignIn') {
        if (loggedUser) next({name: 'Home'})
        else next()
    } else {
        if (loggedUser) {
            loggedUser = JSON.parse(loggedUser)
            await store.dispatch('signIn', {
                username: loggedUser.username,
                password: loggedUser.password,
                errorCallback: (error) => {
                    if (error.response.status == 404) {
                        vueInstance.notify({text: error.response.data});
                    }
                }
            })
            next()
        } else {
            console.log('going to sign in')
            next({name: 'SignIn'})
        }
    }
})

export default router
