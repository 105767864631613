<template>
    <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="date"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                    v-model="date"
                    :label="label"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    :disabled="disabled"
            ></v-text-field>
        </template>
        <v-date-picker v-model="date" locale="ar" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">اغلاق</v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">اختيار</v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
    export default {
        name: "DatePicker",
        props: {
            value: {
                required: true
            },
            label: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                date: this.value
            }
        },
        watch: {
            date: function () {
                this.$emit('input', this.date)
            }
        }
    }
</script>

<style scoped>

</style>
