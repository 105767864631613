<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-btn
            class="mb-2 floatedBtn"
            v-if="isAdmin"
            @click="dialog = true"
        >
          خبر جديد
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          v-for="newsObject in news"
          cols="12"
          sm="6"
          md="4"
          lg="3"
      >
        <NewsCard :news="newsObject" @deleteNews="deleteItem" @editNews="editItem" @showNews="showNews"/>
      </v-col>
    </v-row>
    <v-dialog
        v-model="dialog"
        max-width="1200px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
          <v-spacer/>
          <v-icon>mdi-newspaper</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="newsForm" :lazy-validation="true">
              <v-row>
                <v-col cols="12" md="6">
                  <v-file-input v-model="editedItem.coverImage"
                                :rules="editedItem.id == -1?[rules.required]: []"
                                label="صورة الغلاف" accept="image/*"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input v-model="editedItem.video" label="فيديو" accept="video/*"/>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-text-field label="العنوان" v-model="editedItem.title"
                                :rules="[rules.required]"/>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-input
                      label="ملخص"
                      outlined>
                    <VueEditor style="flex:1;" placeholder="ملخص" v-model="editedItem.summary"/>
                  </v-input>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-input
                      label="الخبر"
                      outlined>
                    <VueEditor style="flex:1;" placeholder="الخبر" v-model="editedItem.text"/>
                  </v-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue floatedBtn"
              text
              @click="close"
              :loading="loading"
          >
            اغلاق
          </v-btn>
          <v-btn
              color="blue floatedBtn"
              text
              @click="save"
              :loading="loading"
          >
            حفظ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue floatedBtn" text @click="closeDelete">لا</v-btn>
          <v-btn color="blue floatedBtn" text @click="deleteItemConfirm">نعم</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="beingShownNews" v-model="showDialog" max-width="1200px">
      <v-card>
        <v-container>
          <v-row v-if="beingShownNews.coverImagePath" justify="center">
            <v-col cols="9">
              <v-img
                  :src="`${$baseUrl}newsCoverImages/${beingShownNews.coverImagePath}`"
              ></v-img>
            </v-col>
          </v-row>
          <v-card-title><h4>{{ beingShownNews.title }}</h4></v-card-title>
          <div v-html="beingShownNews.summary"/>
          <v-divider class="pb-3"/>
          <v-row>
            <v-col col="12" md="7">
              <div v-html="beingShownNews.text"/>
            </v-col>
            <v-col col="12" md="5">
              <video v-if="beingShownNews.videoPath"
                     controls
                     style="height:250px; width:100%"
                     :src="`${$baseUrl}newsVideos/${beingShownNews.videoPath}`"/>
            </v-col>
          </v-row>
        </v-container>
        <v-spacer></v-spacer>
        <v-btn
            color="blue floatedBtn"
            text
            @click="closeShowDialog"
        >
          اغلاق
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import formValidation from "@/formValidation"
import {VueEditor} from 'vue2-editor'
import {mapGetters} from 'vuex'
import NewsCard from "@/components/News/NewsCard";

export default {
  name: "newsList",
  components: {VueEditor, NewsCard},
  data() {
    return {
      rules: {},
      dialog: false,
      dialogDelete: false,
      news: [],
      editedIndex: -1,
      beingShownNews: null,
      showDialog: false,
      loading: false,
      editedItem: {
        id: -1,
        title: '',
        text: '',
        summary: '',
        coverImage: null,
        video: null
      },
      defaultItem: {
        id: -1,
        title: '',
        text: '',
        summary: '',
        coverImage: null,
        video: null
      },
    }
  },

  computed: {
    ...mapGetters([
      'isAdmin'
    ]),
    formTitle() {
      return this.editedIndex === -1 ? 'خبر جديد' : 'تعديل الخبر'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.news.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.news.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    showNews(news) {
      this.beingShownNews = news
      this.showDialog = true
    },
    closeShowDialog() {
      this.showDialog = false
      this.beingShownNews = null
    },
    async deleteItemConfirm() {
      await this.$deleteRequest('news', this.editedItem.id)
      this.news.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      try {
        this.rules = formValidation.rules
        if (this.$refs.newsForm.validate()) {
          this.loading = true
          const {
            title,
            summary,
            text,
            coverImage,
            video
          } = this.editedItem
          let form = new FormData()
          form.append('title', title)
          form.append('summary', summary)
          form.append('text', text)
          if (this.editedItem.id != -1) form.append('id', this.editedItem.id)
          if (coverImage) form.append('coverImage', coverImage)
          if (video) form.append('video', video)
          let data = await this.$postRequest('news', '', form)
          await this.getNews();
          this.notify({textType: this.snackBarTextType.saveSuccessfully});
          this.close()
        }
      } catch (error) {
        this.notify({textType: this.snackBarTextType.saveError});
      } finally {
        this.loading = false
      }
    },
    async getNews() {
      let news = await this.$getRequest('news', '')
      this.news = news
    }
  },
  async mounted() {
    this.getNews()
  }
}
</script>

<style scoped>

</style>
