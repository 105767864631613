<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="meetingOrders"
        :loading="loading"
        :options.sync="pagination"
        :server-items-length="totalModels"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>طلبات حجز القاعات</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>

          <v-spacer></v-spacer>
          <v-dialog
              v-if="isAdmin || isManagementBuilding"
              v-model="dialog"
              max-width="1000px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mb-2 floatedBtn"
                  v-bind="attrs"
                  v-on="on"
                  :loading="loading"
              >
                طلب جديد
              </v-btn>
            </template>
            <v-card :loading="loading">
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                <v-spacer/>
                <v-icon>mdi-seat</v-icon>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="meetingOrderForm" :lazy-validation="true">
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.name"
                            label="الاسم"
                            :rules="[rules.required]"
                            :disabled="viewMode"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            type="number"
                            v-model="editedItem.personsCount"
                            label="عدد الأشخاص"
                            :rules="[rules.required]"
                            @change="()=>{if(editedItem.personsCount < 0) editedItem.personsCount = 0;}"
                            :disabled="viewMode"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="4"
                          md="4"
                      >
                        <DatePicker
                            label="التاريخ"
                            v-model="editedItem.meetingDate"
                            :disabled="viewMode"
                        />
                      </v-col>
                      <v-col
                          cols="12"
                          sm="4"
                          md="4"
                      >
                        <v-menu
                            ref="menu"
                            v-model="startTimeMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="editedItem.startTime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="editedItem.startTime"
                                label="وقت البداية"
                                readonly
                                :disabled="viewMode"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                              v-if="startTimeMenu"
                              v-model="editedItem.startTime"
                              full-width
                              @click:minute="$refs.menu.save(editedItem.startTime)"
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="4"
                          md="4"
                      >
                        <v-menu
                            ref="endTimeMenu"
                            v-model="endTimeMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="editedItem.endTime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="editedItem.endTime"
                                label="وقت الانتهاء"
                                readonly
                                :disabled="viewMode"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                              v-if="endTimeMenu"
                              v-model="editedItem.endTime"
                              full-width
                              @click:minute="$refs.endTimeMenu.save(editedItem.endTime)"
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col v-if="viewMode && editedItem.meetingOrderStateId == 3" cols="12">
                        سبب الرفض: {{ editedItem.rejectReason }}
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="close"
                    :loading="loading"
                >
                  اغلاق
                </v-btn>
                <v-btn
                    v-if="!viewMode"
                    color="blue floatedBtn"
                    text
                    @click="save"
                    :loading="loading"
                >
                  حفظ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text :loading="loading" @click="closeDelete">لا</v-btn>
                <v-btn color="blue floatedBtn" text :loading="loading" @click="deleteItemConfirm">نعم
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="closeOrderConfirmationObject.showRejectConfirmDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">أمتأكد من رفض الطلب؟
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field label="سبب رفض الطلب" v-model="editedItem.rejectReason"
                                    :rules="[rules.required]"/>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text :loading="loading"
                       @click="closeOrderConfirmationObject.showRejectConfirmDialog = false">لا
                </v-btn>
                <v-btn color="blue floatedBtn" text :loading="loading"
                       @click="rejectMeetingOrder(closeOrderConfirmationObject.orderId)">
                  نعم
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="assignAHallAttributes.assignToHallDialog" max-width="500px">
            <v-card v-if="assignAHallAttributes.meetingOrder">
              <v-card-title class="text-h5">تحديد قاعة و قبول الطلب
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                          :items="meetingHalls"
                          label="القاعة"
                          :rules="[rules.required]"
                          v-model="assignAHallAttributes.meetingOrder.meetingHallId"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="blue floatedBtn"
                      text
                      @click="closeAssignDialog"
                      :loading="loading"
                  >
                    اغلاق
                  </v-btn>
                  <v-btn
                      v-if="!viewMode"
                      color="blue floatedBtn"
                      text
                      @click="reserveAHall"
                      :loading="loading"
                  >
                    حفظ
                  </v-btn>
                </v-card-actions>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                @click="viewItem(item)"
                v-bind="attrs"
                v-on="on"
            >
              mdi-eye
            </v-icon>
          </template>
          <span>عرض الطلب</span>
        </v-tooltip>
        <v-tooltip bottom v-if="(isAdmin || isMeeter) && (item.meetingOrderStateId == 1)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>تعديل الطلب</span>
        </v-tooltip>
        <v-tooltip bottom v-if="(isAdmin || isMeeter) && item.meetingOrderStateId == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-icon

                small
                @click="assignAHall(item)"
                v-bind="attrs"
                v-on="on"
            >
              mdi-redo
            </v-icon>
          </template>
          <span>تحديد قاعة و قبول الطلب</span>
        </v-tooltip>
        <v-tooltip bottom v-if="(isAdmin || isMeeter) && item.meetingOrderStateId == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-icon

                small
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>حذف الطلب</span>
        </v-tooltip>
        <v-tooltip bottom v-if="(isAdmin || isMeeter) && item.meetingOrderStateId == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                @click="openFinishOrderConfirmation(item.id)"
                v-bind="attrs"
                v-on="on"
            >
              mdi-close
            </v-icon>
          </template>
          <span>إغلاق الطلب</span>
        </v-tooltip>
      </template>
      <template v-slot:item.state="{ item }">
        <div class="statusDiv"
             :style="`width:100%;background-color:${item.state.color}`">
          {{ item.state.name }}
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import formValidation from "@/formValidation"
import DatePicker from "@/components/common/DatePicker.vue";

export default {
  name: "meetingOrdersList",
  components: {DatePicker},
  data() {
    return {
      loading: false,
      rules: {},
      dialog: false,
      dialogDelete: false,
      viewMode: false,
      startTimeMenu: false,
      endTimeMenu: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'الاسم',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'التاريخ',
          align: 'start',
          sortable: false,
          value: 'meetingDate',
        },
        {
          text: 'وقت البداية',
          align: 'start',
          sortable: false,
          value: 'startTime',
        },
        {
          text: 'وقت النهاية',
          align: 'start',
          sortable: false,
          value: 'endTime',
        },
        {
          text: 'عدد الاشخاص',
          align: 'start',
          sortable: false,
          value: 'personsCount',
        },
        {
          text: 'الحالة',
          align: 'start',
          sortable: false,
          value: 'state',
        },
        {
          text: 'أدوات',
          align: 'start',
          sortable: false,
          value: 'actions',
        }
      ],
      meetingOrders: [],
      meetingHalls: [],
      editedIndex: -1,
      filters: {
        q: ''
      },
      totalModels: -1,
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      editedItem: {
        id: -1,
        name: '',
        startTime: '',
        endTime: '',
        meetingDate: '',
        personsCount: 0,
        rejectReason: '',
        meetingHallId: null,
        meetingOrderStateId: 1
      },
      defaultItem: {
        id: -1,
        name: '',
        startTime: '',
        endTime: '',
        meetingDate: '',
        personsCount: 0,
        rejectReason: '',
        meetingHallId: null,
        meetingOrderStateId: 1
      },
      closeOrderConfirmationObject: {
        stateId: -1,
        orderId: -1,
        showRejectConfirmDialog: false
      },
      assignAHallAttributes: {
        meetingOrder: null,
        assignToHallDialog: false
      }
    }
  },

  computed: {
    ...mapGetters([
      'loggedUser',
      'isAdmin',
      'isMeeter',
      'isManagementBuilding'
    ]),
    formTitle() {
      return this.editedIndex === -1 ? 'طلب جديد' : 'تعديل الطلب'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    pagination: {
      deep: true,

      handler: function () {
        this.getMeetingOrders()
      }
    }
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.meetingOrders.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.viewMode = false
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.meetingOrders.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async viewItem(item) {
      this.editedIndex = this.meetingOrders.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.viewMode = true
      this.dialog = true
    },

    async deleteItemConfirm() {
      await this.$deleteRequest('meetingOrders', this.editedItem.id)
      this.meetingOrders.splice(this.editedIndex, 1)
      this.closeDelete()
    },
    search() {
      this.getMeetingOrders()
    },
    close() {
      this.dialog = false
      this.viewMode = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeView() {
      this.viewDialog = false
      this.closeOrderConfirmationObject = {
        stateId: -1,
        orderId: -1,
        showConfirmDialog: false,
        showRejectConfirmDialog: false,
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    verifySelectedReturnObjects(objects) {
      return objects.every(o => o.count)
    },
    permitAcceptStatusChanged() {
      if (!this.editedItem.isPermitAccepted) {
        this.editedItem.guardId = null
      }
    },
    async save() {
      try {
        this.rules = formValidation.rules


        if (this.$refs.meetingOrderForm.validate()) {
          this.loading = true
          try {
            if (this.editedItem.personsCount < 1) {
              this.notify({text: 'حقل العدد يجب ان يكون اكبر من الصفر'});
              return
            }
            this.loading = true
            if (this.editedItem.id == -1) {
              await this.$postRequest('meetingOrders', '', this.editedItem)
              this.getMeetingOrders()
            } else {
              await this.$putRequest('meetingOrders', this.editedItem.id, this.editedItem)
              this.getMeetingOrders()
            }
            this.notify({textType:this.snackBarTextType.saveSuccessfully});
            this.close()

          } catch (error) {
            if (error.response.state == 400) {
              this.notify({text: error.response.data.msg});
            }
          } finally {
            this.loading = false
          }
        }
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async getMeetingOrders() {
      try {
        this.loading = true
        let meetingOrders = await this.$getRequest('meetingOrders', '', {
          me: this.isEmployee ? true : undefined,
          q: this.filters.q,
          page: this.pagination.page,
          pageSize: this.pagination.itemsPerPage,
        })
        this.meetingOrders = meetingOrders.data
        this.totalModels = meetingOrders.total
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async sendACOrderReport() {
      try {
        this.loading = true
        await this.$postRequest('meetingOrders', 'reportACOrder', {
          airConditions: this.airConditions,
          orderId: this.editedItem.id
        })
        this.closeView()
        this.getMeetingOrders()
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async sendPermitAllowance() {
      try {
        this.loading = true
        await this.$postRequest('meetingOrders', 'storePermitAllowance', {
          isPermitAccepted: this.editedItem.isPermitAccepted,
          guardId: this.editedItem.guardId,
          orderId: this.editedItem.id
        })
        this.closeView()
        this.getMeetingOrders()
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    openCloseOrderConfirmation(stateId, orderId) {
      this.closeOrderConfirmationObject = {
        stateId,
        orderId,
        showConfirmDialog: true,
        showRejectConfirmDialog: false,
      }
    },
    openFinishOrderConfirmation(orderId) {
      this.editedItem.rejectReason = ''
      this.closeOrderConfirmationObject = {
        orderId,
        showConfirmDialog: false,
        showRejectConfirmDialog: true
      }
    },
    assignAHall(meetingOrder) {
      this.assignAHallAttributes = {
        meetingOrder,
        assignToHallDialog: true
      }
    },
    closeAssignDialog() {
      this.assignAHallAttributes = {
        meetingOrder: null,
        assignToHallDialog: false
      }
    },
    async reserveAHall() {
      try {
        this.loading = true
        await this.$postRequest('meetingOrders', `reserve/${this.assignAHallAttributes.meetingOrder.id}`, {
          meetingHallId: this.assignAHallAttributes.meetingOrder.meetingHallId
        })
        this.closeAssignDialog()
        await this.getMeetingOrders()
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async rejectMeetingOrder() {
      try {
        this.loading = true
        await this.$postRequest('meetingOrders', `reject/${this.closeOrderConfirmationObject.orderId}`, {
          rejectReason: !this.editedItem.rejectReason ? '' : this.editedItem.rejectReason,
        })
        this.editedItem.rejectReason = ''
        this.closeView()
        await this.getMeetingOrders()
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async getMeetingHalls() {
      let data = await this.$getRequest('meetingHalls', '')
      this.meetingHalls = data.map(s => {
        return {
          text: s.name,
          value: s.id
        }
      })
    }
  },
  async mounted() {
    await this.getMeetingHalls()
    await this.getMeetingOrders()
  }
}
</script>

<style scoped>
@media print {
  .table {
    background-color: red;
  }
}

.table {
  background-color: red;
}
</style>

