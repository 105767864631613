<template>
    <v-container>
        <v-row v-if="servicesReports.length != 0" class="mt-6">
            <v-col
                    cols="12"
                    md="4"
            >
                <v-btn icon text @click="downloadOrdersReport" :disabled="dates.length == 1" color="primary"><v-icon>mdi-printer</v-icon></v-btn>
                <br>
                <v-date-picker
                        v-model="dates"
                        color="var(--color-gold)"
                        range
                ></v-date-picker>
                <v-row class="mt-6">
                    <v-col cols="9">
                        <v-text-field
                                v-model="dateRangeText"
                                label="مجال التواريخ"
                                prepend-icon="mdi-calendar"
                                readonly
                        ></v-text-field>
                    </v-col>
                    <v-col
                            cols="12"
                            sm="6"
                            md="6"
                    >
                        <v-select
                                v-model="filters.serviceId"
                                label="الخدمة"
                                :items="services"
                        ></v-select>
                    </v-col>
                    <v-col
                            cols="12"
                            sm="6"
                            md="6"
                    >
                        <v-select
                                v-model="filters.statusId"
                                label="الحالة"
                                :items="statuses"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="8">
                <v-row class="pa-0">
                    <v-col cols="3" class="pa-0 d-flex align-center justify-center">
                        <v-btn icon text @click="getServicesCountsReports" :disabled="dates.length == 1"><v-icon>mdi-refresh</v-icon></v-btn>
                        <v-btn icon text @click="downloadCountsReports" :disabled="dates.length == 1"><v-icon>mdi-printer</v-icon></v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-for="serviceReport in servicesReports" cols="12" md="6">
                        <ReportCard :title="`طلبات خدمة ${serviceReport.name}`" :count="serviceReport.finishedOrdersCount" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import moment from 'moment'
    import {mapGetters} from 'vuex'
    import ReportCard from "@/components/Home/ReportCard";

    export default {
        name: "Home",
        components:{ReportCard},
        data: () => ({
            dates: [],
            services: [],
            statuses: [],
            servicesReports:[],
            filters:{
                serviceId: -1,
                statusId: -1
            }
        }),
        computed: {
            ...mapGetters(['isEmployee','loggedUser','isAdmin','isGeneralManager']),
            dateRangeText () {
                return this.dates.join(' ~ ')
            }
        },
        methods:{
            isItInLoggedUserServices(serviceId){
              if(this.isAdmin || this.isGeneralManager) return true
              return this.loggedUser.services.some(s=>s.id == serviceId)
            },
            async getServicesCountsReports(){
                let data = await this.$getRequest('service','countsReports',{
                    startDate:this.dates.length > 0?this.dates[0]:undefined,
                    endDate:this.dates.length > 1?moment(this.dates[1]).add(1,'day').format('yyyy-MM-DD'):undefined,
                    serviceId:this.filters.serviceId,
                    statusId:this.filters.statusId,
                })
                data = data.filter(s=>this.isItInLoggedUserServices(s.id))
                this.servicesReports = data
            },
            async downloadCountsReports(){
                window.open(`${this.$baseUrl}generate-report?serviceId=${this.filters.serviceId}&statusId=${this.filters.statusId}&uikd=${this.loggedUser.id}&startDate=${this.dates.length > 0?this.dates[0]:undefined}&endDate=${this.dates.length > 1?moment(this.dates[1]).add(1,'day').format('yyyy-MM-DD'):undefined}`)
            },
            async downloadOrdersReport(){
                window.open(`${this.$baseUrl}generate-orders-report?serviceId=${this.filters.serviceId}&statusId=${this.filters.statusId}&uikd=${this.loggedUser.id}&startDate=${this.dates.length > 0?this.dates[0]:undefined}&endDate=${this.dates.length > 1?moment(this.dates[1]).add(1,'day').format('yyyy-MM-DD'):undefined}`)
            },
            async getServices() {
                let services = await this.$getRequest('service', '')
                this.services = services.map(s=>{
                    return {
                        text: s.name,
                        value: s.id
                    }
                })
                this.services.unshift({
                    text:'الكل',
                    value:-1
                })
            },
            async getStatuses() {
                let statuses = await this.$getRequest('status', '')
                this.statuses = statuses.map(s=>{
                    return {
                        text: s.name,
                        value: s.id
                    }
                })
                this.statuses.unshift({
                    text:'الكل',
                    value:-1
                })
            }
        },
        mounted(){
            if(!this.loggedUser.contactNumber || (this.loggedUser.building && !this.loggedUser.building.keeperContactNumber)){
                this.$router.push({name:'ContactNumber'})
            }
            else {
                if (this.isEmployee) {
                    this.$router.replace({name: 'News'})
                } else this.getServicesCountsReports()
            }
            this.getServices()
            this.getStatuses()
        }
    }
</script>

<style scoped>

</style>
