import {mapActions} from 'vuex'

const snackBarTextType = {
    saveSuccessfully: 'تم الحفظ بنجاح',
    saveError: 'حصل خطأ اثناء الحفظ',
    deleteSuccessfully: 'تم الحذف بنجاح',
    deleteError: 'حصل خطأ اثناء الحذف'
}
export default {
    data(){
        return{
            snackBarTextType
        }
    },
    methods:{
        ...mapActions(['showSnackbar']),
        notify({text, textType}){
            this.showSnackbar({text: textType ? textType : text})
        }
    }
}