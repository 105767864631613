import axios from "axios";
import vueInstance from '@/main.js'
 var baseUrl = `https://api.jed-ssa.com/api/`
// var baseUrl = `http://localhost:8000/api/`;
// var baseUrl = `http://192.168.1.80:8000/api/`
var secret = "71!%^fc*mh&o";

export default {
  getRequest: async function (controllerName, urlPath, data) {
    try {
      let url = `${baseUrl}${controllerName}/${urlPath}`;
      if (urlPath == "") url = url.slice(0, -1);
      let resp = await axios.get(url, {
        params: data,
        headers: {
          k: secret,
          uid: window.uId,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  postRequest: async function (controllerName, urlPath, data) {
    try {
      let url = `${baseUrl}${controllerName}/${urlPath}`;
      if (urlPath == "") url = url.slice(0, -1);
      let resp = await axios.post(url, data, {
        headers: {
          k: secret,
          uid: window.uId,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  putRequest: async function (controllerName, urlPath, data) {
    try {
      let url = `${baseUrl}${controllerName}/${urlPath}`;
      if (urlPath == "") url = url.slice(0, -1);
      let resp = await axios.put(url, data, {
        headers: {
          k: secret,
          uid: window.uId,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  deleteRequest: async function (controllerName, urlPath, data) {
    try {
      let url = `${baseUrl}${controllerName}/${urlPath}`;
      if (urlPath == "") url = url.slice(0, -1);
      let resp = await axios.delete(url, {
        data,
        headers: {
          k: secret,
          uid: window.uId,
        },
      });
      vueInstance.notify({textType: this.snackBarTextType.deleteSuccessfully});
      return resp.data;
    } catch (error) {
      vueInstance.notify({textType: this.snackBarTextType.deleteError});
      console.log(error);
      throw error;
    }
  },
  baseUrl,
};
