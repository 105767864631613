<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="administrations"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>الادارات</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-if="!isAppInViewMode"
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mb-2 floatedBtn"
                  v-bind="attrs"
                  v-on="on"
                  v-if="isAdmin"
              >
                ادارة جديدة
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                <v-spacer/>
                <v-icon>mdi-seat</v-icon>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="administrationForm" :lazy-validation="true">
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.name"
                            label="الاسم"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="close"
                    :loading="loading"
                >
                  اغلاق
                </v-btn>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="save"
                    :loading="loading"
                >
                  حفظ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text @click="closeDelete">لا</v-btn>
                <v-btn color="blue floatedBtn" text @click="deleteItemConfirm">نعم</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            v-if="isAdmin && !isAppInViewMode"
            small
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            v-if="isAdmin && !isAppInViewMode"
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import formValidation from "@/formValidation"
import {mapGetters} from 'vuex'

export default {
  name: "administrationsList",
  data() {
    return {
      rules: {},
      dialog: false,
      dialogDelete: false,
      loading: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'الاسم',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'أدوات',
          align: 'start',
          sortable: false,
          value: 'actions',
        }
      ],
      administrations: [],
      editedIndex: -1,
      editedItem: {
        id: -1,
        name: ''
      },
      defaultItem: {
        id: -1,
        name: ''
      },
    }
  },

  computed: {
    ...mapGetters([
      'isGeneralManager',
      'isAdmin',
      'isAppInViewMode'
    ]),
    formTitle() {
      return this.editedIndex === -1 ? 'ادارة جديدة' : 'تعديل ادارة'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.administrations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.administrations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      await this.$deleteRequest('administration', this.editedItem.id)
      this.administrations.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      try{
        this.rules = formValidation.rules
        if (this.$refs.administrationForm.validate()) {
          this.loading = true
          const {name} = this.editedItem
          if (this.editedItem.id == -1) {
            let data = await this.$postRequest('administration', '', {name})
            this.administrations.push(data)
          } else {
            let data = await this.$putRequest('administration', this.editedItem.id, {name})
            Object.assign(this.administrations[this.editedIndex], data)
          }
          this.notify({textType:this.snackBarTextType.saveSuccessfully});
          this.close()
        }
      }
      catch(error){
        this.notify({textType:this.snackBarTextType.saveError});
      }
      finally {
        this.loading = false
      }
    },
  },
  async mounted() {
    let administrations = await this.$getRequest('administration', '')
    this.administrations = administrations
  }
}
</script>

<style scoped>

</style>
