<template>
  <v-container class="my-7">
    <v-toolbar flat>
      <v-toolbar-title>الأسئلة الشائعة</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-dialog v-model="inquireFormDialog" max-width="800px">
        <template v-if="isAdmin || isEmployee" v-slot:activator="{ on, attrs }">
          <v-btn
            class="mb-2 floatedBtn"
            v-bind="attrs"
            v-on="on"
          >
            بطاقة جديدة
          </v-btn>
        </template>
        <InquireForm @closeInquireForm="closeInquireForm" />
      </v-dialog>
      <v-dialog v-if="isAdmin || isServiceManager || isServicesManager" v-model="dialog" max-width="800px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mb-2 floatedBtn"
            v-bind="attrs"
            v-on="on"
          >
            سؤال جديد
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
            <v-spacer />
            <v-icon>mdi-help</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="FAQForm" :lazy-validation="true">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="editedItem.serviceId"
                      label="الخدمة"
                      :rules="[rules.required]"
                      :items="services"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.title"
                      label="السؤال"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <VueEditor
                      v-model="editedItem.answer"
                      placeholder="الاجابة"
                      :rules="[rules.required]"
                    ></VueEditor>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue floatedBtn"
              :loading="loading"
              text
              @click="close"
            >
              اغلاق
            </v-btn>
            <v-btn
              color="blue floatedBtn"
              :loading="loading"
              text
              @click="save"
            >
              حفظ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue floatedBtn"
              :loading="loading"
              text
              @click="closeDelete"
              >لا</v-btn
            >
            <v-btn
              color="blue floatedBtn"
              :loading="loading"
              text
              @click="deleteItemConfirm"
              >نعم</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-expansion-panels focusable>
      <ServiceFAQs
        v-for="service in FAQs"
        :service="service"
        :editable="isAdmin || isServiceManager || isServicesManager"
        @editQuestion="editItem"
        @deleteQuestion="deleteItem"
      />
    </v-expansion-panels>
  </v-container>
</template>

<script>
import formValidation from "@/formValidation";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import ServiceFAQs from "@/components/FAQ/ServiceFAQs";
import InquireForm from "@/components/Order/Inquire/InquireForm.vue";

export default {
  name: "FAQsList",
  components: {InquireForm, VueEditor, ServiceFAQs },
  data() {
    return {
      rules: {},
      dialog: false,
      dialogDelete: false,
      loading: false,
      inquireFormDialog: false,
      FAQs: [],
      services: [],
      editedIndex: -1,
      editedItem: {
        id: -1,
        title: "",
        answer: "",
        serviceId: -1,
      },
      defaultItem: {
        id: -1,
        title: "",
        answer: "",
        serviceId: -1,
      },
    };
  },

  computed: {
    ...mapGetters(['isEmployee', "isAdmin", "isServiceManager", "isServicesManager", 'loggedUser']),
    formTitle() {
      return this.editedIndex === -1 ? "سؤال جديد" : "تعديل سؤال";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    closeInquireForm() {
      this.inquireFormDialog = false;
      this.$router.push({name: 'Inquires'})
    },
    editItem(item) {
      this.editedIndex = this.FAQs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.FAQs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        this.loading = true;
        await this.$deleteRequest("FAQ", this.editedItem.id);
        this.FAQs.splice(this.editedIndex, 1);
        this.closeDelete();
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async closeDelete() {
      this.dialogDelete = false;
      await this.getFAQs();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      try {
        this.rules = formValidation.rules;
        if (this.$refs.FAQForm.validate()) {
          this.loading = true;
          let { serviceId, title, answer } = this.editedItem;
          if (this.editedItem.id == -1) {
            let data = await this.$postRequest("FAQ", "", {
              serviceId,
              title,
              answer,
            });
            await this.getFAQs();
          } else {
            let data = await this.$putRequest("FAQ", this.editedItem.id, {
              serviceId,
              title,
              answer,
            });
            await this.getFAQs();
          }
          this.notify({textType: this.snackBarTextType.saveSuccessfully});
          this.close();
        }
      } catch (error) {
        this.notify({textType: this.snackBarTextType.saveError});
      } finally {
        this.loading = false;
      }
    },
    async getServices() {
      let services = await this.$getRequest("service", "");
      this.services = services
        .filter((s) =>
          !this.isAdmin
            ? this.loggedUser.services.some((lus) => lus.id == s.id)
            : true
        )
        .map((s) => {
          return {
            text: s.name,
            value: s.id,
          };
        });
    },
    async getFAQs() {
      let FAQs = await this.$getRequest("FAQ", "");
      this.FAQs = FAQs.filter((s) =>
        this.isServiceManager && this.isServicesManager
          ? this.loggedUser.services.some((lus) => lus.id == s.id)
          : true
      );
    },
  },
  async mounted() {
    this.getFAQs();
    this.getServices();
  },
};
</script>

<style scoped></style>
