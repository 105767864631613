<template>
  <v-container class="d-flex justify-center pa-0">
    <v-card max-width="1000px">
      <v-card-title>استفسار جديد</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="inquireForm" :lazy-validation="true">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="inquire.serviceId"
                  label="الخدمة"
                  :rules="[rules.required]"
                  :items="services"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="inquire.inquireTypeId"
                  label="النوع"
                  :rules="[rules.required]"
                  :items="inquireTypes"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                    :items="inquireTitlesOptions"
                    label="عنوان البطاقة"
                    :rules="[rules.required]"
                    v-model="inquire.title"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="inquire.email"
                  label="البريد الالكتروني"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="inquire.buildingName"
                  label="اسم المنشأة"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="inquire.phoneNumber"
                  label="رقم الهاتف"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <VueEditor
                  v-model="inquire.text"
                  placeholder="الاستفسار أو المشكلة"
                  :rules="[rules.required]"
                ></VueEditor>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue floatedBtn" :loading="loading" text @click="save">
          حفظ
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import formValidation from "@/formValidation";
import { VueEditor } from "vue2-editor";

export default {
  name: "InquireForm",
  components: { VueEditor },
  data() {
    return {
      rules: {},
      loading: false,
      inquire: {
        serviceId: -1,
        title: "",
        buildingName: "",
        phoneNumber: "",
        text: "",
        email: "",
        inquireTypeId: 1,
      },
      inquireTypes: [],
      services: [],
      inquireTitles: []
    };
  },
  computed:{
    inquireTitlesOptions(){
      if(!this.inquireTitles) return [];
      return this.inquireTitles.filter(title=>title.inquireTypeId == this.inquire.inquireTypeId)
    },
  },
  methods: {
    async save() {
      try {
        this.rules = formValidation.rules;
        if (this.$refs.inquireForm.validate()) {
          this.loading = true;
          await this.$postRequest("inquire", "", this.inquire);
          this.notify({text: this.snackBarTextType.saveSuccessfully});
          this.$emit("closeInquireForm");
        }
      } catch (error) {
        this.notify({textType: this.snackBarTextType.saveError});
      } finally {
        this.loading = false;
      }
    },
    async getServices() {
      let services = await this.$getRequest("service", "");
      this.services = services.map((s) => {
        return {
          value: s.id,
          text: s.name,
        };
      });
    },
    async getInquireTypes() {
      let inquireTypes = await this.$getRequest("inquire", "types");
      this.inquireTypes = inquireTypes.map((type) => {
        return {
          value: type.id,
          text: type.name,
        };
      });
    },
    async getInquireTitles() {
      let inquireTitles = await this.$getRequest("inquire", "titles");
      this.inquireTitles = inquireTitles.map((title) => {
        return {
          value: title.name,
          text: title.name,
          inquireTypeId: title.inquireTypeId
        };
      });
    },
  },
  async mounted() {
    await this.getInquireTypes();
    await this.getServices();
    await this.getInquireTitles();
  },
};
</script>

<style scoped></style>
