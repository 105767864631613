<template>
  <v-container
    style="box-shadow: 0px 0px 3px black; height: 65vh"
    class="d-flex flex-column"
  >
    <v-container
      fluid
      id="messagesContainer"
      style="flex: 0.99; overflow-y: auto"
    >
      <v-row>
        <v-col v-for="message in messages" cols="12" :key="message.id">
          <MessageCard :message="message" />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!isInquireFinished && !isInquireManager" fluid style="flex: 0.01">
      <v-text-field
        label="الرسالة"
        outlined
        append-outer-icon="mdi-send"
        @click:append-outer="sendMessage"
        @keydown.enter="sendMessage"
        v-model="message"
        :loading="loading"
        hide-details
      />
    </v-container>
  </v-container>
</template>

<script>
import MessageCard from "@/components/Order/Inquire/MessageCard";
import {mapGetters} from "vuex";

export default {
  name: "ChatList",
  components: { MessageCard },
  props: {
    inquire: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: "",
      messages: [],
      loading: false,
      pusherChannel: null,
      inquireId: -1
    };
  },
  computed:{
    ...mapGetters(['isInquireManager']),
    isInquireFinished(){
      if(!this.inquire) return true
      return this.inquire.inquireStateId == 3
    }
  },
  methods: {
    async sendMessage() {
      try {
        if (this.message != "") {
          this.loading = true;
          await this.$postRequest("inquireMessages", "", {
            inquireId: this.inquireId,
            text: this.message,
          });
          this.message = "";
        }
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async getMessages() {
      try {
        this.loading = true;
        this.messages = await this.$getRequest(
          "inquireMessages",
          `getMessages/${this.inquireId}`
        );
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    newPusherMessage({ message }) {
      this.messages.push(message);
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    scrollToBottom() {
      const container = document.getElementById("messagesContainer");
      container.scroll({ top: container.scrollHeight, behavior: "smooth" });
    },
  },
  async mounted() {
    this.inquireId = this.inquire.id
    await this.getMessages();
    this.pusherChannel = this.$pusher.subscribe("chat");
    this.pusherChannel.bind(
      `chat.inquire.${this.inquireId}`,
      this.newPusherMessage
    );
    this.scrollToBottom();
  },
};
</script>

<style scoped></style>
