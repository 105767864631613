import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        drawer: true,
        loggedUser: null,
        snackbar: {
            text: '',
            show: false
        }
    },
    mutations: {
        setLoggedUser(state, user) {
            state.loggedUser = user;
            if (user) {
                localStorage.setItem(
                    process.env.VUE_APP_LOCALSTORAGE_USER_KEY,
                    JSON.stringify(user)
                );
            } else {
                localStorage.removeItem(process.env.VUE_APP_LOCALSTORAGE_USER_KEY);
            }
        },
        setDrawer(state, drawer) {
            state.drawer = drawer;
        },
        showSnackbar(state, {text}) {
            state.snackbar.text = text;
            state.snackbar.show = true;
        },
    },
    actions: {
        async signIn({commit, dispatch}, {username, password, callback, errorCallback}) {
            try {
                let data = await this._vm.$postRequest("users", "login", {
                    username,
                    password,
                });
                window.uId = data.id;
                commit("setLoggedUser", data);
                if (callback) callback();
            } catch (error) {
                if (error.response && error.response.status == 404) {
                    dispatch("logOut");
                    if (errorCallback) errorCallback(error)
                }
            }
        },
        logOut({commit}) {
            window.uId = undefined;
            commit("setLoggedUser", null);
            commit("setDrawer", false);
            try {
                router.replace({name: "SignIn"});
            } catch (error) {
            }
        },
        switchDrawer({commit, state}) {
            commit("setDrawer", !state.drawer);
        },
        showSnackbar({commit}, {text}) {
            commit("showSnackbar", {text});
        },
    },
    getters: {
        loggedUser(state) {
            return state.loggedUser;
        },
        snackbar(state) {
            return state.snackbar;
        },
        isAppInViewMode(state, getters) {
            if (!state.loggedUser) return false;
            return getters.isGeneralManager || getters.isBFViewer;
        },
        isAdmin(state, getters) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 25000 || getters.isGeneralManager;
        },
        isEmployee(state) {
            if (!state.loggedUser) return false;
            return (
                state.loggedUser.roleId == 6532 ||
                state.loggedUser.roleId == 6533 ||
                state.loggedUser.roleId == 6534
            );
        },
        isServicesManager(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 7000;
        },
        isServiceManager(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 6800;
        },
        isManager(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 10000;
        },
        isSchoolBuilding(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 6532;
        },
        isManagementBuilding(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 6533;
        },
        isEducationalBuilding(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 6534;
        },
        isGeneralManager(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 24000;
        },
        isBudgetRunner(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 6801;
        },
        isTechnical(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 7500;
        },
        isBFViewer(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 10003;
        },
        isMeeter(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 10002;
        },
        isInquireManager(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 6802;
        },
        isBFUser(state) {
            if (!state.loggedUser) return false;
            return state.loggedUser.roleId == 10001;
        },
        hasMeetingsPermission(state, getters) {
            if (!state.loggedUser) return false;
            return state.loggedUser.canShowMeetings == 1 || getters.isMeeter;
        },
        hasFinancialPermission(state, getters) {
            if (!state.loggedUser) return false;
            return state.loggedUser.canManageFinancial == 1 || getters.isBFUser;
        },
    },
});
