<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="users"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>المستخدمون</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-text-field
              class="mt-5"
              label="البحث"
              append-icon="mdi-magnify"
              @click:append="search"
              v-model="filters.name"
              @keydown.enter="search"
              dense
              solo
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              max-width="800px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mb-2 floatedBtn"
                  v-bind="attrs"
                  v-on="on"
                  v-if="!isGeneralManager"
              >
                مستخدم جديد
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                <v-spacer/>
                <v-icon>mdi-account</v-icon>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="usersForm" :lazy-validation="true">
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.name"
                            name="name"
                            autocomplete="false"
                            label="الاسم الكامل"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.username"
                            name="username"
                            autocomplete="false"
                            label="اسم المستخدم"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.password"
                            name="new-password"
                            type="password"
                            autocomplete="false"
                            label="كلمة السر"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.contactNumber"
                            name="contactNumber"
                            autocomplete="false"
                            label="رقم التواصل"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-autocomplete
                            label="النوع"
                            :items="roles"
                            item-text="name"
                            item-value="id"
                            v-model="editedItem.roleId"
                            :rules="[rules.required]"
                            @change="editedItem.serviceIds = null"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                          cols="12"
                          v-if="editedItem.roleId == 6800 || editedItem.roleId == 7000 || editedItem.roleId == 10000"
                      >
                        <v-autocomplete
                            label="الخدمات"
                            :items="services"
                            item-text="name"
                            item-value="id"
                            v-model="editedItem.serviceIds"
                            :rules="[rules.required]"
                            @input="servicesLimiter"
                            multiple
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                          cols="12"
                          v-if="editedItem.roleId == 10003"
                      >
                        <v-autocomplete
                            label="البنود للمتابعة"
                            :items="allocations"
                            item-text="name"
                            item-value="id"
                            multiple
                            chips
                            return-object
                            v-model="editedItem.viewable_allocations"
                            :rules="[rules.required]"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          v-if="editedItem.roleId == 6532"
                      >
                        <v-autocomplete
                            label="المبنى"
                            :items="buildings"
                            item-text="name"
                            item-value="id"
                            v-model="editedItem.buildingId"
                            :rules="[rules.required]"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          v-if="isAdmin"
                      >
                        <v-checkbox label="معالجة الاجتماعات" v-model="editedItem.canShowMeetings"/>
                      </v-col>

                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          v-if="isAdmin"
                      >
                        <v-checkbox label="الادارة المالية" v-model="editedItem.canManageFinancial"
                                    @change="editedItem.administrationId = null; editedItem.committeeId = null;"/>
                      </v-col>
                      <v-col
                          v-if="editedItem.canManageFinancial"
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-autocomplete
                            label="نوع مدخل البيانات"
                            :items="committees"
                            item-text="name"
                            item-value="id"
                            multiple
                            chips
                            return-object
                            v-model="editedItem.committees"
                            :rules="[rules.required]"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                          v-if="editedItem.canManageFinancial && isSelectedOnlyJihaTalibe"
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-autocomplete
                            label="الادارة"
                            :items="administrations"
                            item-text="name"
                            item-value="id"
                            v-model="editedItem.administrationId"
                            :rules="[rules.required]"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                    :loading="loading"
                >
                  اغلاق
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                    :loading="loading"
                >
                  حفظ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">هل انت متاكد من الحذف؟</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">لا</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">نعم</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            v-if="!isGeneralManager"
            small
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            v-if="!isGeneralManager"
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.services="{ item }">
        {{ item.services.map(s => s.name).join(', ') }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import formValidation from "@/formValidation"
import {mapGetters} from 'vuex'

export default {
  name: "Users",
  data() {
    return {
      rules: {},
      users: [],
      roles: [],
      administrations: [],
      allocations: [],
      committees: [],
      services: [],
      buildings: [],
      loading: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "النوع",
          align: 'start',
          sortable: false,
          value: 'role.name',
        },
        {
          text: "الخدمات",
          align: 'start',
          sortable: false,
          value: 'services',
        },
        {
          text: "المبنى",
          align: 'start',
          sortable: false,
          value: 'building.name',
        },
        {
          text: "الاسم الكامل",
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: "الافعال",
          align: 'start',
          sortable: false,
          value: 'actions',
        }
      ],
      editedIndex: -1,
      editedItem: {
        id: -1,
        name: '',
        username: '',
        password: '',
        contactNumber: '',
        viewable_allocations: [],
        committees: [],
        buildingId: null,
        serviceIds: null,
        canShowMeetings: false,
        canManageFinancial: false,
        administrationId: null,
        committeeId: null,
        roleId: -1,
      },
      defaultItem: {
        id: -1,
        name: '',
        username: '',
        password: '',
        contactNumber: '',
        viewable_allocations: [],
        committees: [],
        buildingId: null,
        serviceIds: null,
        canShowMeetings: false,
        canManageFinancial: false,
        administrationId: null,
        committeeId: null,
        roleId: -1,
      },
      filters: {
        name: ''
      }
    }
  },

  computed: {
    ...mapGetters(['isAdmin', 'loggedUser', 'isGeneralManager']),
    formTitle() {
      return this.editedIndex === -1 ? "مستخدم جديد" : "تعديل المستخدم"
    },
    isSelectedOnlyJihaTalibe() {
      if (!this.editedItem.committees) return false
      return this.editedItem.committees.every(c => c.id == 1)
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    //put user object for editing and open the edit dialog
    //
    //
    editItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      const {services} = this.editedItem
      this.editedItem.serviceIds = services.map(s => s.id)
      this.dialog = true
    },

    //put user object for delete and open the delete dialog
    //
    //
    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    //delete the item and request to server
    //
    //
    async deleteItemConfirm() {
      await this.$deleteRequest('users', '', {id: this.editedItem.id})
      this.users.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    //close the Edit/New dialog
    //
    //
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    //close the delete dialog
    //
    //
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    servicesLimiter(e) {
      if (this.editedItem.roleId == 6800 && e.length > 1) {
        e.pop()
      }
    },
    //user save function
    //
    //
    async save() {
      try {
        this.rules = formValidation.rules
        if (this.$refs.usersForm.validate()) {
          this.loading = true
          const {roleId} = this.editedItem
          if (roleId != 6532) this.editedItem.buildingId = null
          if (roleId != 6800 && roleId != 7000 && roleId != 10000) this.editedItem.serviceIds = []
          if (roleId != 10003) this.editedItem.viewable_allocations = []
          if (this.editedItem.canManageFinancial) this.editedItem.comittees = []
          if (this.editedItem.viewable_allocations.find(va => va.id == -1)) {
            this.editedItem.viewable_allocations = this.allocations.filter(a => a.id != -1)
          }
          if (this.editedIndex > -1) {
            let data = await this.$putRequest('users', '', this.editedItem)
            Object.assign(this.users[this.editedIndex], data)
          } else {
            let data = await this.$postRequest('users', '', this.editedItem)
            this.users.push(data)
          }
          await this.getUsers()
          this.notify({textType: this.snackBarTextType.saveSuccessfully});
          this.close()
        }
      } catch (error) {
        this.notify({textType: this.snackBarTextType.saveError});
      } finally {
        this.loading = false
      }
    },
    search() {
      this.getUsers()
    },
    async getUsers() {
      this.users = await this.$getRequest('users', '', {name: this.filters.name})
    },
    async getServices() {
      let data = await this.$getRequest('service', '')
      if (!this.isAdmin) {
        data = data.filter(d => this.loggedUser.services.some(s => s.id == d.id))
      }
      this.services = data
    },
    async getBuildings() {
      let data = await this.$getRequest('building', '')
      this.buildings = data
    }
  },
  async mounted() {
    await this.getUsers()
    await this.getServices()
    await this.getBuildings()
    this.roles = await this.$getRequest('users', 'getRoles')
    this.roles = this.roles.map(r => {
      return {
        ...r,
        disabled: this.loggedUser.roleId != 25000 && [25000, 24000, 10003, 10002, 10001, 10000, 6801, this.loggedUser.roleId].includes(r.id)
      }
    })
    this.administrations = await this.$getRequest('administration', '')
    this.allocations = await this.$getRequest('allocation', '')
    this.committees = await this.$getRequest('committee', '')
    this.allocations.unshift({
      id: -1,
      name: 'الكل'
    })
  }
}
</script>

<style scoped>

</style>
