<template>
  <v-app style="background: #F1EDE9" dark class="myApp">
    <AppHeader/>
    <sideMenu v-if="loggedUser"/>
    <v-main class="mt-5">
      <router-view/>
      <v-snackbar
          v-model="snackbar.show"
          color="var(--color-green)"
          :timeout="3500"
          bottom
          right
          dark
      >
        <h5 style="color:white !important;">
          {{ snackbar.text }}
        </h5>

        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar.show = false"
              style="color: white !important;"
          >
            اغلاق
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from "@/components/AppHeader"
import sideMenu from "@/components/sideMenu"
import {mapGetters} from 'vuex'

export default {
  name: 'App',
  components: {AppHeader, sideMenu},
  computed: {
    ...mapGetters(['loggedUser', 'snackbar'])
  }
};
</script>
<style>
@font-face {
  font-family: "GESS";
  src: local("GESS"),
  url(./assets/fonts/ArbFONTS-GE-SS-Two-Bold.otf) format("truetype");
}

* {
  font-family: 'GESS', roboto, sans-serif !important;
}

.hovering {
  transition: all 0.2s ease-in-out;
}

.hovering:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.statusDiv {
  min-width: 30%;
  padding: 3px;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
}
</style>
