<template>
    <v-card @click="showNews(news)">
        <v-img
                height="250"
                :src="`${$baseUrl}newsCoverImages/${news.coverImagePath}`"
        ></v-img>
        <v-card-title><h6>{{news.title}}</h6></v-card-title>
        <v-card-text>
            <v-divider class="pb-3"/>
            <div v-html="news.summary"/>
        </v-card-text>
        <v-card-actions v-if="isAdmin">
            <v-icon
                    @click.stop="deleteNews(news)"
                    color="red"
            >
                mdi-delete
            </v-icon>
            <v-icon
                    @click.stop="editNews(news)"
            >
                mdi-pencil
            </v-icon>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "NewsCard",
        props:{
            news:{
                required: true,
                type: Object
            }
        },
        computed:{
            ...mapGetters(['isAdmin'])
        },
        methods:{
            deleteNews(news){
                this.$emit('deleteNews', news)
            },
            editNews(news){
                this.$emit('editNews', news)
            },
            showNews(news){
                this.$emit('showNews', news)
            }
        }
    }
</script>

<style scoped>

</style>
