<template>
    <v-container fluid>
        <h3>المكيفات</h3>
        <v-divider/>
        <v-row v-for="ac in airConditions">
            <v-col v-if="!reportMode" cols="1"/>
            <v-col cols="3">
                <v-text-field :disabled="viewMode" type="number" label="العدد" v-model="ac.count"/>
            </v-col>
            <v-col :cols="5">
                <v-text-field :disabled="viewMode" label="الموقع" v-model="ac.location"/>
            </v-col>
            <v-col cols="3">
                <div class="d-flex" v-if="!isEmployee">
                    <v-radio-group :disabled="viewMode && !reportMode" v-model="ac.state">
                        <v-radio color="primary" value="تالف" label="تالف"></v-radio>
                        <v-radio color="primary" value="يمكن صيانته" label="يمكن صيانته"></v-radio>
                    </v-radio-group>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="!viewMode">
            <v-col cols="1" class="d-flex justify-center align-center">
                <v-btn text icon @click="addAirCondition"><v-icon color="primary">mdi-plus</v-icon></v-btn>
            </v-col>
            <v-col cols="3">
                <v-text-field type="number" label="العدد" v-model="defaultAirCondition.count"/>
            </v-col>
            <v-col :cols="!reportMode?8:4">
                <v-text-field label="الموقع" v-model="defaultAirCondition.location"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "newACList",
        props:['airConditionsProp','viewMode','reportMode'],
        computed:{
            ...mapGetters(['isEmployee'])
        },
        data(){
            return{
                airConditions: [],
                defaultAirCondition:{
                   count:1,
                   location:''
                }
            }
        },
        methods:{
            addAirCondition(){
                this.airConditions.push(Object.assign({},this.defaultAirCondition))
                this.defaultAirCondition = {
                    count:1,
                    location:''
                }
            }
        },
        mounted(){
            if(this.airConditionsProp){
                this.airConditions.push(...this.airConditionsProp.map(ac=>{
                    return {
                        ...ac,
                        count:1
                    }
                }))
            }
        }
    }
</script>

<style scoped>

</style>
