<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <BarChart :dataProp="barData"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="9">
        <v-data-table
            class="fill-height"
            :headers="headers"
            :items="expenses"
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>المصروفات</v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-menu
                  v-model="showFiltersDatePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="290"
                  bottom
                  :offset-y="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      readonly
                      label="بحث من خلال الشهر"
                      class="mt-7"
                      v-model="filters.date"
                      dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="filters.date" color="primary" dense type="month" locale="ar"/>
              </v-menu>
              <v-spacer></v-spacer>
              <v-dialog
                  v-model="dialog"
                  max-width="800px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mb-2 floatedBtn"
                      v-bind="attrs"
                      v-on="on"
                      v-if="isEmployee"
                  >
                    مصروف جديد
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    <v-spacer/>
                    <v-icon>mdi-expense</v-icon>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-form ref="expenseForm" :lazy-validation="true">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.billNumber"
                                label="رقم الفاتورة"
                                :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.value"
                                label="قيمة الفاتورة"
                                :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.note"
                                label="ملاحظات"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue floatedBtn"
                        text
                        :loading="loading"
                        @click="close"
                    >
                      اغلاق
                    </v-btn>
                    <v-btn
                        color="blue floatedBtn"
                        text
                        :loading="loading"
                        @click="save"
                    >
                      حفظ
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-expensed>
                  <v-expensed-title class="text-h5">متأكد من الحذف؟</v-expensed-title>
                  <v-expensed-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue floatedBtn" :loading="loading" text @click="closeDelete">لا</v-btn>
                    <v-btn color="blue floatedBtn" :loading="loading" text @click="deleteItemConfirm">نعم
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-expensed-actions>
                </v-expensed>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.created_at="{ item }">
            <h4>{{ $moment(item.created_at).format('yyyy-MM-DD') }}</h4>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="3">
        <CircularProgress
            :value="expensesFromBudget"
            :size="120"
            :width="8"
            :rotate="270"
            :title="`المصروف :${expensesFromBudget} %`"
            :subTitle="` الميزانية :${budget}`"
            :extraSubTitle="` قيمة المصروف :${expensesSum}`"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import formValidation from "@/formValidation"
import {mapGetters} from 'vuex'
import BarChart from '@/components/expenses/Bar'
import CircularProgress from "@/components/expenses/CircularProgress"

export default {
  name: "expensesList",
  components: {BarChart, CircularProgress},
  data() {
    return {
      rules: {},
      dialog: false,
      dialogDelete: false,
      loading: false,
      showFiltersDatePicker: false,
      headers: [
        {
          text: 'رقم الفاتورة',
          align: 'start',
          sortable: false,
          value: 'billNumber',
        },
        {
          text: 'المبلغ',
          align: 'start',
          sortable: false,
          value: 'value',
        },
        {
          text: 'ملاحظات',
          align: 'start',
          sortable: false,
          value: 'note',
        },
        {
          text: 'التاريخ',
          align: 'start',
          sortable: false,
          value: 'created_at',
        }
      ],
      expenses: [],
      editedIndex: -1,
      editedItem: {
        id: -1,
        value: 0,
        billNumber: '',
        note: ''
      },
      defaultItem: {
        id: -1,
        value: 0,
        billNumber: '',
        note: ''
      },
      filters: {
        date: null,
        month: undefined,
        year: undefined
      },
      barData: []
    }
  },

  computed: {
    ...mapGetters([
      'isEmployee'
    ]),
    formTitle() {
      return this.editedIndex === -1 ? 'مصروف جديد' : ''
    },
    budget() {
      if (this.expenses.length == 0) return 0
      return this.expenses[0].budget.value
    },
    expensesSum() {
      return this.expenses.reduce((sum, expense) => sum + +expense.value, 0)
    },
    expensesFromBudget() {
      if (this.budget == 0) return 0
      return (this.expensesSum * 100 / this.budget).toFixed(1)
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    'filters.date': function () {
      let dateMoment = this.$moment(this.filters.date)
      this.filters.month = +(dateMoment.format('MM'))
      this.filters.year = +(dateMoment.format('yyyy'))
      this.getExpenses()
    }
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.expenses.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.expenses.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      try {
        this.loading = true
        await this.$deleteRequest('expense', this.editedItem.id)
        this.expenses.splice(this.editedIndex, 1)
        this.closeDelete()
      } catch (error) {
      } finally {
        this.loading = false
      }
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      try {
        this.rules = formValidation.rules
        if (this.$refs.expenseForm.validate()) {
          let {billNumber, note, value} = this.editedItem
          this.loading = true
          if (this.editedItem.id == -1) {
            let data = await this.$postRequest('expense', '', {billNumber, note, value})
            this.getExpenses()
          }
          // else {
          //     let data = await this.$putRequest('expense', this.editedItem.id, {panelNumber,color,model,driverName,driverNumber})
          //     this.getExpenses()
          // }
          this.notify({textType: this.snackBarTextType.saveSuccessfully});
          this.close()
        }
      } catch (error) {
        if (error.response && error.response.status == 400) {
          this.notify({text: error.response.data});
        } else {
          this.notify({textType: this.snackBarTextType.saveError});
        }
      } finally {
        this.loading = false
      }
    },
    async getExpenses() {
      let expenses = await this.$getRequest('expense', '', {
        me: this.isEmployee ? true : undefined,
        month: this.filters.month,
        year: this.filters.year
      })
      this.expenses = expenses
    },
    getMonthSum(monthNumber, yearData) {
      return yearData.reduce((sum, datum) => sum + +(datum.budget.month == monthNumber ? datum.value : 0), 0)
    },
    async initBarData() {
      const yearData = await this.$getRequest('expense', '', {
        year: this.$moment().format('yyyy'),
        me: this.isEmployee ? true : undefined,
      })
      for (let i = 1; i < 13; i++) {
        this.barData.push(this.getMonthSum(i, yearData))
      }
    }
  },
  async mounted() {
    await this.getExpenses()
    await this.initBarData()
  },

}
</script>

<style scoped>

</style>
