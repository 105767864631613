<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="steps"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>سير العملية</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              v-if="isAdmin && !isAppInViewMode"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mb-2 floatedBtn"
                  v-bind="attrs"
                  v-on="on"
              >
                سير عملية جديد
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                <v-spacer/>
                <v-icon>mdi-octagon</v-icon>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="stepForm" :lazy-validation="true">
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.name"
                            label="الاسم"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            type="number"
                            :min="1"
                            v-model="editedItem.sort"
                            label="رقم سير العملية"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            type="number"
                            :min="0"
                            v-model="editedItem.daysDuration"
                            label="مدة سير العملية"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-autocomplete
                            label="اللجنة"
                            :items="committees"
                            item-text="name"
                            item-value="id"
                            v-model="editedItem.committeeId"
                            :rules="[rules.required]"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-autocomplete
                            label="نوع القيمة"
                            :items="valueTypes"
                            v-model="editedItem.valueType"
                            :rules="[rules.required]"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="close"
                    :loading="loading"
                >
                  اغلاق
                </v-btn>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="save"
                    :loading="loading"
                >
                  حفظ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text @click="closeDelete">لا</v-btn>
                <v-btn color="blue floatedBtn" text @click="deleteItemConfirm">نعم</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            v-if="isAdmin && !isAppInViewMode"
            small
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            v-if="isAdmin && !isAppInViewMode"
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import formValidation from "@/formValidation"
import CircularProgress from "@/components/expenses/CircularProgress"
import {mapGetters} from 'vuex'

export default {
  name: "stepsList",
  data() {
    return {
      rules: {},
      dialog: false,
      dialogDelete: false,
      loading: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'الاسم',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'رقم الخطوة',
          align: 'start',
          sortable: false,
          value: 'sort',
        },
        {
          text: 'نوع القيمة',
          align: 'start',
          sortable: false,
          value: 'valueType',
        },
        {
          text: 'اللجنة',
          align: 'start',
          sortable: false,
          value: 'committee.name',
        },
        {
          text: 'أدوات',
          align: 'start',
          sortable: false,
          value: 'actions',
        }
      ],
      steps: [],
      committees: [],
      editedIndex: -1,
      valueTypes: [
        {
          value: 'TEXT',
          text: 'TEXT',
        },
        {
          value: 'DATE',
          text: 'DATE',
        }
      ],
      editedItem: {
        id: -1,
        name: '',
        sort: 1,
        committeeId: null,
        valueType: 'TEXT',
        daysDuration: 1
      },
      defaultItem: {
        id: -1,
        name: '',
        sort: 1,
        committeeId: null,
        valueType: 'TEXT',
        daysDuration: 1
      },
    }
  },

  computed: {
    ...mapGetters([
      'isAdmin',
      'isGeneralManager',
      'isAppInViewMode'
    ]),
    formTitle() {
      return this.editedIndex === -1 ? 'سير عملية جديد' : 'تعديل سير العملية'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.steps.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.steps.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      await this.$deleteRequest('step', this.editedItem.id)
      this.steps.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      try{
        this.rules = formValidation.rules
        if (this.$refs.stepForm.validate()) {
          this.loading = true
          const {name, sort, valueType, committeeId, daysDuration} = this.editedItem
          if (this.editedItem.id == -1) {
            let data = await this.$postRequest('step', '', {name, sort, valueType, committeeId, daysDuration})
          } else {
            let data = await this.$putRequest('step', this.editedItem.id, {
              name,
              sort,
              valueType,
              committeeId,
              daysDuration
            })
          }
          this.setSteps()
          this.notify({textType: this.snackBarTextType.saveSuccessfully});
          this.close()
        }
      }
      catch(error){
        this.notify({textType: this.snackBarTextType.saveError});
      }
      finally {
        this.loading = false
      }
    },
    async setSteps() {
      let steps = await this.$getRequest('step', '')
      this.steps = steps
    }
  },
  async mounted() {
    this.setSteps()
    this.committees = await this.$getRequest('committee', '')
  }
}
</script>

<style scoped>

</style>
