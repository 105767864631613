<template>
    <v-menu
            bottom
            offset-y
            rounded
            :close-on-click="true"
            max-width="30%"
            max-height="60vh"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-badge
                    :content="notifications.length"
                    :value="notifications.length"
                    color="var(--color-gold)"
                    overlap

            >
                <v-icon smallv-bind="attrs"
                        v-on="on">
                    mdi-bell
                </v-icon>
            </v-badge>

        </template>

        <v-list>
            <template v-for="(notification, index) in notifications">
                <v-list-item>
                    <v-list-item-icon>
                        <h4>
                            #{{notification.order.id}}
                        </h4>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{ notification.text }}
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
            </template>
        </v-list>
    </v-menu>
</template>
<script>

    import {mapGetters} from 'vuex'

    export default {
        name: "NotificationsMenu",
        data() {
            return {
                notifications: [],
                pusherChannel: null
            }
        },
        computed: {
            ...mapGetters(['loggedUser', 'isServiceManager', 'isServicesManager', 'isEmployee'])
        },
        mounted() {
            this.pusherChannel = this.$pusher.subscribe('notifications')
            this.pusherChannel.bind(`notifications.orderStatusChanged.${this.loggedUser.id}`, (notification) => {
                this.notifications.unshift(notification)
            });
            if (!this.isEmployee) {
                if (this.isAdmin) {
                    this.pusherChannel.bind(`notifications.newOrder.all'}`, this.newOrder);
                } else if (!this.isTechnical)
                    this.loggedUser.services.forEach(service => {
                        this.pusherChannel.bind(`notifications.newOrder.${service.id}`, this.newOrder);
                    })
                else
                    this.loggedUser.services.forEach(service => {
                        this.pusherChannel.bind(`notifications.newOrder.7`, this.newOrder);
                    })

            }
        }
    }
</script>

<style scoped>

</style>
