<template>
    <v-card class="reportCard">
        <v-card-title>
            <v-row>
                {{title}}
                <v-spacer/>
                <v-icon>{{icon}}</v-icon>
            </v-row>
        </v-card-title>
        <v-card-actions>
            المنتهية: {{count}}
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "ReportCard",
        props:{
            title:{
                type:String,
                required:true
            },
            count:{
                type:String,
                required:true
            },
            icon:{
                type:String,
                required:false
            }
        }
    }
</script>

<style scoped>
.reportCard{
    border-radius: 8px;
    padding: 5px;
}
</style>
