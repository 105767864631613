<template>
  <v-container>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ service.name }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-expansion-panels focusable>
          <v-expansion-panel
            v-for="question in service.faqs"
            :key="question.id"
            class="my-5"
          >
            <v-expansion-panel-header>
              <v-row>
                <v-col cols="9">
                  {{ question.title }}
                </v-col>
                <v-col cols="3">
                  <v-icon
                    v-if="editable"
                    small
                    class="mr-2"
                    @click="editItem(question)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon v-if="editable" small @click="deleteItem(question)">
                    mdi-delete
                  </v-icon>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="my-3 mx-2"
              v-html="question.answer"
            />
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-container>
</template>

<script>
export default {
  name: "ServiceFAQs",
  props: {
    service: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    editItem(question) {
      this.$emit("editQuestion", question);
    },
    deleteItem(question) {
      this.$emit("deleteQuestion", question);
    },
  },
};
</script>

<style scoped></style>
