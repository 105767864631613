<template>
    <div class="containerPrint">
        <div class="returnObjectsHeader">
            <div>
                {{order.building.name}}
            </div>
        </div>
        <table class="x">
            <thead>
                <th style="width: 20%">الاسم</th>
                <th style="width: 10%">العدد</th>
                <th style="width: 70%">ملاحظات</th>
            </thead>
            <tbody>
                <tr v-for="returnObject in returnObjects">
                    <td style="width: 20%">{{returnObject.name}}</td>
                    <td style="width: 15%">{{returnObject.pivot.objectsCount}}</td>
                    <td style="width: 70%">{{returnObject.pivot.note}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "ReturnObjects",
        props: {
            order: {
                type: Array,
                required: true
            }
        },
        computed:{
            returnObjects(){
                return this.order.return_objects
            }
        },
        mounted(){
            console.log(this.returnObjects)
        }
    }
</script>

<style scoped>
</style>
