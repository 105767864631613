<template>
    <v-dialog
            v-model="dialog"
            max-width="500px"
            persistent
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">تعديل رقم التواصل</span>
                <v-spacer/>
                <v-icon>mdi-phone</v-icon>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="contactNumberFormRef" :lazy-validation="true">
                        <v-row>
                            <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                            >
                                <v-text-field
                                        v-model="number"
                                        label="رقم التواصل"
                                        :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                            >
                                <v-text-field
                                        v-model="keeperContactNumber"
                                        label="رقم تواصل الحارس"
                                        :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="blue floatedBtn"
                        text
                        @click="save"
                        :loading="saving"
                >
                    حفظ
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import formValidation from "@/formValidation"
    import {mapGetters} from 'vuex'

    export default {
        name: "insertContactNumber",
        data(){
            return{
                rules:{},
                number:'',
                keeperContactNumber:'',
                saving:false,
                dialog:true
            }
        },
        computed:{
            ...mapGetters(['loggedUser'])
        },
        methods:{
            async save(){
                try{

                    this.rules = formValidation.rules
                    if (this.$refs.contactNumberFormRef.validate()) {
                        this.saving = true
                        await this.$postRequest('users','changeContactNumber',{
                            number:this.number,
                            keeperContactNumber:this.keeperContactNumber,
                            userId:this.loggedUser.id
                        })
                        this.$router.push({name: 'Home'})
                    }
                }
                catch(error){}
                finally {
                    this.saving = false
                }
            }
        }
    }
</script>

<style scoped>

</style>
