<template>
  <v-container>
    <v-data-table :headers="headers" :items="inquires">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>الاستفسارات</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-select
              :items="selectServices"
              v-model="filters.serviceId"
              label="الخدمة"
              class="mt-5"
          />
          <v-select
              :items="inquireTypes"
              v-model="filters.inquireTypeId"
              label="النوع"
              class="mt-5"
          />
          <v-spacer></v-spacer>
          <v-icon @click="getInquires">mdi-refresh</v-icon>
          <v-dialog v-if="editedItem.id != -1" v-model="dialog">
            <v-card style="min-height: 80vh">
              <v-card-title
                  style="background-color: var(--color-green)"
                  class="white--text"
              >
                <span class="text-h5">{{ formTitle }}</span>
                <v-spacer/>
                <span class="text-h5 mx-3">{{selectedInquireDate}}</span>
                <v-icon color="white">mdi-help</v-icon>
              </v-card-title>
              <v-card-text>
                <v-container style="min-height: 65vh">
                  <v-row class="fill-height">
                    <v-col cols="12" md="4" class="fill-height">
                      <h3>الخدمة: {{ editedItem.service.name }}</h3>
                      <h3>اسم المنشأة: {{ editedItem.user.building ? editedItem.user.building.name : '' }}</h3>
                      <h3>رقم الهاتف: {{ editedItem.user.contactNumber }}</h3>
                      <v-divider/>
                      <h3>العنوان</h3>
                      <h4 v-html="editedItem.title"/>
                      <h3>المشكلة</h3>
                      <h4 v-html="editedItem.text"/>
                    </v-col>
                    <v-col cols="12" md="8">
                      <ChatList :inquire="editedItem"/>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="close"
                >
                  اغلاق
                </v-btn>
                <v-btn
                    v-if="canCloseTheTicket && !isSelectedInquireFinished"
                    color="danger"
                    text
                    @click="markInquireAsFinished"
                    :loading="finishedButtonLoading"
                >
                  الإنتهاء من هذه البطاقة
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text @click="closeDelete"
                >لا
                </v-btn
                >
                <v-btn color="blue floatedBtn" text @click="deleteItemConfirm"
                >نعم
                </v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.state="{ item }">
        <div class="statusDiv"
             :style="`width:100%;background-color:${item.state.id == 1?'var(--color-dark-blueish)':(item.state.id == 2?'red':'#7D5D79')}`">
          {{ item.state.name }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="viewItem(item)"> mdi-eye</v-icon>
        <v-icon small @click="deleteItem(item)" v-if="canCloseTheTicket">
          mdi-delete
        </v-icon>
        <v-tooltip bottom v-if="canSendTheTicket && item.inquireStateId == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                small
                v-bind="attrs"
                v-on="on"
                @click="changeTicketStateToSent(item)"
                :loading="sendingTicket"
                icon
            >
              <v-icon small>
                mdi-send
              </v-icon>
            </v-btn>

          </template>
          <span>ارسال لمدير الخدمة</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import formValidation from "@/formValidation";
import {mapGetters} from "vuex";
import ChatList from "@/components/Order/Inquire/ChatList";

export default {
  name: "InquiresList",
  components: {ChatList},
  data() {
    return {
      rules: {},
      dialog: false,
      dialogDelete: false,
      finishedButtonLoading: false,
      sendingTicket: false,
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "النوع",
          align: "start",
          sortable: false,
          value: "type.name",
        }, {
          text: "الخدمة",
          align: "start",
          sortable: false,
          value: "service.name",
        },
        {
          text: "اسم المنشأة",
          align: "start",
          sortable: false,
          value: "user.building.name",
        },
        {
          text: "رقم الهاتف",
          align: "start",
          sortable: false,
          value: "user.contactNumber",
        },
        {
          text: "العنوان",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: "الحالة",
          align: "start",
          sortable: false,
          value: "state",
        },
        {
          text: "أدوات",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      inquires: [],
      services: [],
      inquireTypes: [],
      editedIndex: -1,
      editedItem: {
        id: -1,
      },
      filters: {
        serviceId: -1,
        inquireTypeId: -1
      },
    };
  },

  computed: {
    ...mapGetters(["isGeneralManager", "isEmployee", "isAdmin", "loggedUser", 'isInquireManager', 'isServiceManager', 'isServicesManager']),
    formTitle() {
      return `استفسار #${this.editedItem.id}`;
    },
    selectServices() {
      return (
          this.services
              .filter((s) =>
                !this.isEmployee && !this.isAdmin && !this.isServicesManager && !this.isInquireManager
                  ? this.loggedUser.services.some((lus) => lus.id == s.id)
                  : true
              )
              .map((s) => {
                return {
                  text: s.name,
                  value: s.id,
                };
              })
      );
    },
    selectedInquireDate(){
      if(!this.editedItem) return ''
      return this.$moment.utc(this.editedItem.created_at).format('YYYY/MM/DD hh:mm A')
    },
    isSelectedInquireFinished(){
      if(!this.editedItem) return true
      return this.editedItem.inquireStateId == 3
    },
    canCloseTheTicket(){
      return this.isAdmin || this.isServiceManager || this.isServicesManager
    },
    canSendTheTicket(){
      return this.isAdmin || this.isInquireManager
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "filters.serviceId": function () {
      this.getInquires();
    },
    "filters.inquireTypeId": function () {
      this.getInquires();
    },
  },
  methods: {
    viewItem(item) {
      this.editedIndex = this.inquires.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.inquires.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.$deleteRequest("inquire", this.editedItem.id);
      this.inquires.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {id: -1});
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async getServices() {
      let services = await this.$getRequest("service", "");
      services.unshift({
        id: -1,
        name: "الكل",
      });
      this.services = services;
    },
    async getInquires() {
      let inquires = await this.$getRequest("inquire", "", {
        serviceId: this.filters.serviceId,
        typeId: this.filters.inquireTypeId
      });
      this.inquires = [...inquires];
    },
    async getInquireTypes() {
      let inquireTypes = await this.$getRequest("inquire", "types", {});
      inquireTypes.unshift({
        id: -1,
        name: "الكل",
      });
      this.inquireTypes = inquireTypes.map(type => {
        return {
          value: type.id,
          text: type.name
        }
      });
    },
    async markInquireAsFinished() {
      try {
        const inquireId = this.editedItem.id
        this.finishedButtonLoading = true
        let newInquire = await this.$putRequest('inquire', `changeFinished/${inquireId}`, {});
        if (this.editedIndex != -1) {
          this.inquires.splice(this.editedIndex, 1, newInquire)
        }
        this.close()
      } catch (error) {

      } finally {
        this.finishedButtonLoading = false
      }
    },
    async changeTicketStateToSent(inquire) {
      try {
        const inquireId = inquire.id
        this.sendingTicket = true
        let newInquire = await this.$putRequest('inquire', `sendToServiceManager/${inquireId}`, {});
        const inquireIndex = this.inquires.findIndex(inquire=>inquire.id == inquireId)
        if (inquireIndex != -1) {
          this.inquires.splice(inquireIndex, 1, newInquire)
        }
      } catch (error) {

      } finally {
        this.sendingTicket = false
      }
    }
  },
  async mounted() {
    await this.getServices();
    await this.getInquires();
    await this.getInquireTypes();
    if (
        !this.isAdmin &&
        !this.isInquireManager &&
        this.loggedUser.services &&
        this.loggedUser.services.length > 0
    ){
      this.services.shift()
      this.filters.serviceId = this.loggedUser.services[0].id;
    }
    if (this.isAdmin) this.filters.serviceId = -1;
  },
};
</script>

<style scoped></style>
