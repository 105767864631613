<template>
    <v-sheet class="fill-height d-flex flex-column align-center">
        <v-container class="d-flex justify-center">{{title}}</v-container>
        <v-progress-circular
                :rotate="rotate"
                :size="size"
                :width="width"
                :value="value"
                :color="color"
        >
            {{ value }}
        </v-progress-circular>
        <v-container class="d-flex justify-center">{{subTitle}}</v-container>
        <v-container class="d-flex justify-center">{{extraSubTitle}}</v-container>
    </v-sheet>
</template>

<script>
    export default {
        name: "CircularProgress",
        props:{
            title:{
                default:''
            },
            subTitle:{
                default:''
            },
            extraSubTitle:{
                default:''
            },
            value:{
                required:true
            },
            size:{
                type:Number,
                default:100
            },
            width:{
                type:Number,
                default:15
            },
            color:{
                type:String,
                default:'primary'
            },
            rotate:{
                type:Number,
                default:0
            }
        }
    }
</script>

<style scoped>

</style>
