<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="allocations"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>البنود</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              v-if="!isAppInViewMode && (hasFinancialPermission || isAdmin)"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mb-2 floatedBtn"
                  v-bind="attrs"
                  v-on="on"
              >
                بند جديد
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                <v-spacer/>
                <v-icon>mdi-file</v-icon>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="allocationForm" :lazy-validation="true">
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.name"
                            label="الاسم"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.abv"
                            label="الرمز"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-autocomplete
                            label="الادارة"
                            :items="administrations"
                            item-text="name"
                            item-value="id"
                            multiple
                            chips
                            return-object
                            v-model="editedItem.administrations"
                            :rules="[rules.required]"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="close"
                    :loading="loading"
                >
                  اغلاق
                </v-btn>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="save"
                    :loading="loading"
                >
                  حفظ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text @click="closeDelete">لا</v-btn>
                <v-btn color="blue floatedBtn" text @click="deleteItemConfirm">نعم</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            v-if="isAdmin && !isAppInViewMode"
            small
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            v-if="isAdmin && !isAppInViewMode"
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.administrations="{item}">
        {{ item.administrations.map(a => a.name).join(', ') }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import formValidation from "@/formValidation"
import CircularProgress from "@/components/expenses/CircularProgress"
import {mapGetters} from 'vuex'

export default {
  name: "allocationsList",
  data() {
    return {
      rules: {},
      dialog: false,
      dialogDelete: false,
      loading: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'الاسم',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'الرمز',
          align: 'start',
          sortable: false,
          value: 'abv',
        },
        {
          text: 'الادارة',
          align: 'start',
          sortable: false,
          value: 'administrations',
        },
        {
          text: 'أدوات',
          align: 'start',
          sortable: false,
          value: 'actions',
        }
      ],
      allocations: [],
      administrations: [],
      editedIndex: -1,
      editedItem: {
        id: -1,
        name: '',
        abv: '',
        administrations: null
      },
      defaultItem: {
        id: -1,
        name: '',
        abv: '',
        administrations: null
      },
    }
  },

  computed: {
    ...mapGetters([
      'isAdmin',
      'isGeneralManager',
      'hasFinancialPermission',
      'isAppInViewMode'
    ]),
    formTitle() {
      return this.editedIndex === -1 ? 'بند جديد' : 'تعديل بند'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.allocations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.allocations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      await this.$deleteRequest('allocation', this.editedItem.id)
      this.allocations.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      try {
        this.rules = formValidation.rules
        if (this.$refs.allocationForm.validate()) {
          this.loading = true
          const {name, abv, administrations} = this.editedItem
          if (this.editedItem.id == -1) {
            let data = await this.$postRequest('allocation', '', {name, abv, administrations})
          } else {
            let data = await this.$putRequest('allocation', this.editedItem.id, {name, abv, administrations})
          }
          this.setAllocations()
          this.notify({textType: this.snackBarTextType.saveSuccessfully});
          this.close()
        }
      } catch (error) {
        this.notify({textType: this.snackBarTextType.saveError});
      } finally {
        this.loading = false
      }
    },
    async setAllocations() {
      let allocations = await this.$getRequest('allocation', '')
      this.allocations = allocations
    }
  },
  async mounted() {
    this.setAllocations()
    this.administrations = await this.$getRequest('administration', '')
  }
}
</script>

<style scoped>

</style>
