<template>
    <v-container>
        <v-data-table
                :headers="headers"
                :items="budgets"
        >
            <template v-slot:top>
                <v-toolbar
                        flat
                >
                    <v-toolbar-title>الميزانيات</v-toolbar-title>
                    <v-divider
                            class="mx-4"
                            inset
                            vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog
                            v-model="dialog"
                            max-width="800px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    class="mb-2 floatedBtn"
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if="!isEmployee"
                            >
                                ميزانية جديدة
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                                <v-spacer/>
                                <v-icon>mdi-budget</v-icon>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-form ref="budgetForm" :lazy-validation="true">
                                        <v-row>
                                            <v-col cols="12" md="5">
                                                <v-date-picker
                                                        v-model="editedItem.date"
                                                        type="month"
                                                        color="primary"
                                                        locale="ar"
                                                        dense
                                                ></v-date-picker>
                                            </v-col>
                                            <v-col cols="12" md="7">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-autocomplete
                                                                v-model="editedItem.buildingId"
                                                                label="المبنى"
                                                                :rules="[rules.required]"
                                                                :items="buildings"
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                                type="number"
                                                                v-model="editedItem.value"
                                                                label="قيمة الميزانية"
                                                                :rules="[rules.required]"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="blue floatedBtn"
                                        text
                                        :loading="loading"
                                        @click="close"
                                >
                                    اغلاق
                                </v-btn>
                                <v-btn
                                        color="blue floatedBtn"
                                        text
                                        :loading="loading"
                                        @click="save"
                                >
                                    حفظ
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-budgetd>
                            <v-budgetd-title class="text-h5">متأكد من الحذف؟</v-budgetd-title>
                            <v-budgetd-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue floatedBtn" :loading="loading" text @click="closeDelete">لا</v-btn>
                                <v-btn color="blue floatedBtn" :loading="loading" text @click="deleteItemConfirm">نعم
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-budgetd-actions>
                        </v-budgetd>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                        v-if="!isEmployee"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                        small
                        @click="deleteItem(item)"
                        v-if="!isEmployee"
                >
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:item.date="{ item }">
                <h4>{{item.year}}-{{item.month}}</h4>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import formValidation from "@/formValidation"
    import {mapGetters} from 'vuex'

    export default {
        name: "budgetsList",
        data() {
            return {
                rules: {},
                dialog: false,
                dialogDelete: false,
                loading: false,
                headers: [
                    {
                        text: 'اسم المبنى',
                        align: 'start',
                        sortable: false,
                        value: 'building.name',
                    },
                    {
                        text: 'الشهر',
                        align: 'start',
                        sortable: false,
                        value: 'date',
                    },
                    {
                        text: 'قيمة الميزانية',
                        align: 'start',
                        sortable: false,
                        value: 'value',
                    }
                ],
                budgets: [],
                buildings: [],
                editedIndex: -1,
                editedItem: {
                    id: -1,
                    buildingId: -1,
                    year: '',
                    month: '',
                    value: 0,
                    date: null
                },
                defaultItem: {
                    id: -1,
                    buildingId: -1,
                    year: '',
                    month: '',
                    value: 0,
                    date: null
                },
            }
        },

        computed: {
            ...mapGetters([
                'isEmployee'
            ]),
            formTitle() {
                return this.editedIndex === -1 ? 'ميزانية جديدة' : 'تعديل ميزانية'
            },
        },
        watch: {
            dialog(val) {
                val || this.close()
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
        },
        methods: {
            editItem(item) {
                this.editedIndex = this.budgets.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem(item) {
                this.editedIndex = this.budgets.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            async deleteItemConfirm() {
                try {
                    this.loading = true
                    await this.$deleteRequest('budget', this.editedItem.id)
                    this.budgets.splice(this.editedIndex, 1)
                    this.closeDelete()
                } catch (error) {
                } finally {
                    this.loading = false
                }
            },

            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            async save() {
                try {
                    this.rules = formValidation.rules
                    if (this.$refs.budgetForm.validate()) {
                        let {buildingId, year, month, value, date} = this.editedItem
                        year = date.split('-')[0]
                        month = +(date.split('-')[1])
                        this.loading = true
                        if (this.editedItem.id == -1) {
                            let data = await this.$postRequest('budget', '', {buildingId, year, month, value})
                            this.getBudgets()
                        }
                        // else {
                        //     let data = await this.$putRequest('budget', this.editedItem.id, {panelNumber,color,model,driverName,driverNumber})
                        //     this.getBudgets()
                        // }
                        this.close()
                    }
                } catch (error) {
                } finally {
                    this.loading = false
                }
            },
            async getBudgets() {
                let budgets = await this.$getRequest('budget', '')
                this.budgets = budgets
                this.getBuildings()
            },
            async getBuildings() {
                let buildings = await this.$getRequest('building', '')
                this.buildings = buildings.map(b => {
                    return {
                        text: b.name,
                        value: b.id
                    }
                })
            }
        },
        async mounted() {
            await this.getBudgets()
        }
    }
</script>

<style scoped>

</style>
