<template>
    <v-sheet>
        <Bar
                :chart-options="chartOptions"
                :chart-data="chartData"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
        />
    </v-sheet>
</template>

<script>
    import { Bar } from 'vue-chartjs/legacy'

    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        BarElement,
        CategoryScale,
        LinearScale
    } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

    export default {
        name: 'BarChart',
        components: {
            Bar
        },
        props: {
            chartId: {
                type: String,
                default: 'bar-chart'
            },
            dataProp:{
                type: Array,
                required:true
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 400
            },
            height: {
                type: Number,
                default: 400
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => {}
            },
            plugins: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                chartData: {
                    labels: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12'
                    ],
                    datasets: [
                        {
                            label: 'المصروفات خلال السنة',
                            backgroundColor: '#008A76',
                            hoverBorderRadius:8,
                            hoverBorderWidth:10,
                            hoverBorderColor:'#E1DAD1',
                            data:this.dataProp
                        }
                    ]
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            }
        }
    }
</script>
