<template>
    <v-row style="width: fit-content; justify-content: space-around">
        <v-col cols="9">
            <v-row class="flex-column text-center">
                <v-col cols="12">
                    <v-icon :color="color" :style="active?`font-size:3rem`:`font-size:2rem`">{{active?'mdi-map-marker':'mdi-octagon'}}</v-icon>
                </v-col>
                <v-col cols="12">
                    {{step.name}}
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="3">
            {{number}}
        </v-col>
    </v-row>

</template>

<script>
    export default {
        name: "Step",
        props:{
            step:{
                required: true,
                type: Object
            },
            number:{
                type:Number,
                default:1
            },
            active:{
                type: Boolean,
                default: false
            },
            color:{
                type:String,
                default:'grey'
            }
        }
    }
</script>

<style scoped>

</style>
