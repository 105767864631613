<template>
    <v-card>
        <v-card-text>
            <h6 class="subtitle-1 blue--text">{{messageName}}</h6>
            <h6 class="subtitle-2 grey--text">{{$moment(message.created_at).format('MM/DD hh:mm')}}</h6>
            <v-divider/>
            <h6 class="subtitle-1 my-5" v-html="message.text"/>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "MessageCard",
        props:{
            message:{
                type:Object,
                required:true
            }
        },
        computed:{
            messageName(){
                if(!this.message) return ''
                let building = this.message.created_user.building
                if(building) return building.name
                return this.message.created_user.role.name
            }
        }
    }
</script>

<style scoped>

</style>
