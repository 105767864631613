<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="orders"
        :loading="loading"
        :options.sync="pagination"
        :server-items-length="totalModels"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>الطلبات</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-text-field
              class="mt-5"
              label="البحث"
              append-icon="mdi-magnify"
              @click:append="search"
              v-model="filters.q"
              @keydown.enter="search"
              dense
              solo
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              max-width="1000px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mb-2 floatedBtn"
                  @click="checkCanStoreNewOrderAndOpenNewOrder"
                  :loading="loading"
              >
                طلب جديد
              </v-btn>
            </template>
            <v-card :loading="loading">
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                <v-spacer/>
                <v-icon>mdi-truck</v-icon>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="orderForm" :lazy-validation="true">
                    <v-row>
                      <v-col
                          v-if="!isEmployee"
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-autocomplete
                            v-model="editedItem.buildingId"
                            label="المبنى"
                            :rules="[rules.required]"
                            :items="buildings"
                            :disabled="editedItem.orderStatusId == 5"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-select
                            v-model="editedItem.serviceId"
                            label="الخدمة"
                            :rules="[rules.required]"
                            :items="services.filter(service=>service.isAvailable == 1)"
                            :disabled="editedItem.orderStatusId == 5"
                        ></v-select>
                      </v-col>
                      <v-col
                          v-if="editedItem.serviceId == 4 && editedItem.id == -1"
                          cols="12"
                      >
                        أنواع الأثاث
                        <v-data-table
                            :key="dialog"
                            :items="returnObjects"
                            show-select
                            v-model="editedItem.returnObjects"
                            :headers="returnObjectsHeaders"
                            item-key="text"
                        >
                          <template v-slot:item.count="{ item }">
                            <v-text-field type="number" min="0" v-model="item.count"/>
                          </template>
                          <template v-slot:item.note="{ item }">
                            <v-text-field v-model="item.note"/>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-row v-if="editedItem.serviceId == 4 && editedItem.id != -1" class="px-6">
                        <v-col cols="12">
                          أنواع الأثاث
                          <v-data-table
                              :items="returnObjects"
                              show-select
                              v-model="editedItem.returnObjects"
                              :headers="returnObjectsHeaders"
                              item-key="text"
                          >
                            <template v-slot:item.count="{ item }">
                              <v-text-field type="number" min="0" v-model="item.count"/>
                            </template>
                            <template v-slot:item.note="{ item }">
                              <v-text-field v-model="item.note"/>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      <v-col
                          v-if="editedItem.serviceId == 1"
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.passengerNumber"
                            label="عدد الركاب"
                            min="1"
                            type="number"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="editedItem.serviceId == 1">
                      <v-col

                          cols="12"
                          sm="6"
                      >
                        <v-text-field type="datetime-local" label="من" :rules="[rules.required]"
                                      color="var(--color-gold)"
                                      v-model="editedItem.carServiceStartDate"/>
                      </v-col>
                      <v-col

                          cols="12"
                          sm="6"
                      >
                        <v-text-field type="datetime-local" label="الى"
                                      :rules="[rules.required]" color="var(--color-gold)"
                                      v-model="editedItem.carServiceEndDate"/>

                      </v-col>
                      <v-col

                          cols="12"
                      >
                        <v-text-field
                            v-model="editedItem.fromLocation"
                            label="من العنوان"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col

                          cols="12"
                      >
                        <v-text-field
                            v-model="editedItem.toLocation"
                            label="الى العنوان"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col

                          cols="12"
                      >
                        <h3>
                          نوع الخدمة
                        </h3>
                        <v-radio-group
                            v-model="editedItem.carJourneyStatus"
                            column
                            :rules="[rules.required]"
                        >
                          <v-radio
                              label="حركة يومية"
                              value="حركة يومية"
                          ></v-radio>
                          <v-radio
                              label="انتدابات"
                              value="انتدابات"
                          ></v-radio>
                          <v-radio
                              label="خارج الدوام"
                              value="خارج الدوام"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12"
                             v-if="editedItem.carJourneyStatus && editedItem.carJourneyStatus != 'حركة يومية'">
                        <h3 class="statusDiv" style="background-color: red; padding:3px">لابد من
                          مخاطبة صاحب الصلاحية المدير العام للتعليم</h3>
                      </v-col>
                    </v-row>
                    <v-row v-if="!isSchoolBuilding && (editedItem.serviceId == 2 || editedItem.serviceId == 3)">
                      <v-col

                          cols="12"
                      >
                        <h3>
                          وزن الصهريج
                        </h3>
                        <v-radio-group
                            v-model="editedItem.tankWeight"
                            column
                            :rules="[rules.required]"
                        >
                          <v-radio
                              label="19 طن"
                              :value="19"
                          ></v-radio>
                          <v-radio
                              label="30 طن"
                              :value="30"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row v-if="editedItem.serviceId == 6">
                      <v-col

                          cols="12"
                      >
                        <v-text-field
                            label="رقم العداد"
                            color="var(--color-gold)"
                            v-model="editedItem.counterNumber"/>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <h3>
                          نوع الخدمة
                        </h3>
                        <v-radio-group
                            v-model="editedItem.issueType"
                            column
                            :rules="[rules.required]"
                        >
                          <v-radio
                              label="مشكلة في الكهرباء"
                              value="كهرباء"
                          ></v-radio>
                          <v-radio
                              label="مشكلة في الماء"
                              value="ماء"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col
                          v-if="editedItem.issueType"
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <h3>
                          نوع المشكلة
                        </h3>
                        <v-radio-group
                            v-model="editedItem.issueName"
                            column
                            :rules="[rules.required]"
                        >
                          <v-radio
                              label="فصل الخدمة"
                              value="فصل الخدمة"
                          ></v-radio>
                          <v-radio
                              v-if="editedItem.issueType == 'ماء'"
                              label="اختفاء الشبكة"
                              value="اختفاء الشبكة"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row v-if="editedItem.serviceId == 7">
                      <newACList ref="newACListRef"
                                 :airConditionsProp="editedItem.air_conditions"/>
                    </v-row>
                    <v-row v-if="editedItem.serviceId == 8">
                      <v-col
                          cols="12"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.permit.buildingName"
                            label="اسم المبنى"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.permit.personName"
                            label="اسم الشخص"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.permit.IDNumber"
                            label="رقم السجل المدني"
                            :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col

                          cols="12"
                          sm="6"
                      >
                        <v-text-field type="datetime-local" label="التاريخ"
                                      :rules="[rules.required]"
                                      color="var(--color-gold)"
                                      v-model="editedItem.permit.entryDateTime"/>
                      </v-col>
                      <v-col

                          cols="12"
                      >
                        <h3>
                          نوع التصريح
                        </h3>
                        <v-radio-group
                            v-model="editedItem.permit.permitType"
                            column
                            :rules="[rules.required]"
                        >
                          <v-radio
                              label="دخول مبنى"
                              value="دخول مبنى"
                          ></v-radio>
                          <v-radio
                              label="خارج الدوام"
                              value="خارج الدوام"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row v-if="!isEmployee && (editedItem.orderStatusId == 2)">
                      <v-col
                          v-if="editedItem.serviceId == 1"
                          cols="12"
                      >
                        <v-select
                            v-model="editedItem.carId"
                            label="السيارة"
                            :items="cars"
                        >
                        </v-select>
                      </v-col>
                      <v-col
                          v-if="editedItem.serviceId != 1 && editedItem.serviceId != 6"
                          cols="12"
                      >
                        <v-select
                            v-model="editedItem.contractorId"
                            label="المتعهد"
                            :items="contractors"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="close"
                    :loading="loading"
                >
                  اغلاق
                </v-btn>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="save"
                    :loading="loading"
                >
                  حفظ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text :loading="loading" @click="closeDelete">لا</v-btn>
                <v-btn color="blue floatedBtn" text :loading="loading" @click="deleteItemConfirm">نعم
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="closeOrderConfirmationObject.showConfirmDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">احرص على اتمام الخدمة لعدم جدولة موعد جديد. أمتأكد من
                الانهاء؟
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text :loading="loading"
                       @click="closeOrderConfirmationObject.showConfirmDialog = false">لا
                </v-btn>
                <v-btn color="blue floatedBtn" text :loading="loading"
                       @click="callChangeStatus(closeOrderConfirmationObject.statusId, closeOrderConfirmationObject.orderId)">
                  نعم
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="closeOrderConfirmationObject.showCloseConfirmDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">أمتأكد من إغلاق الطلب؟
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                          v-model="editedItem.forceCloseReason"
                          label="سبب إغلاق الطلب"
                          :rules="[rules.required]"
                          :items="closingReasons"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text :loading="loading"
                       @click="closeOrderConfirmationObject.showCloseConfirmDialog = false">لا
                </v-btn>
                <v-btn color="blue floatedBtn" text :loading="loading"
                       @click="callChangeStatus(4, closeOrderConfirmationObject.orderId)">
                  نعم
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="viewRejectionDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">أمتأكد من رفض الطلب؟
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                          v-model="orderForRejection.currentRejectionReasonId"
                          label="سبب رفض الطلب"
                          :rules="[rules.required]"
                          :items="rejectionReasons"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue floatedBtn" text :loading="loading"
                       @click="viewRejectionDialog = false">لا
                </v-btn>
                <v-btn color="blue floatedBtn" text :loading="loading"
                       @click="callChangeStatus(5, orderForRejection.id)">
                  نعم
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>


          <v-dialog
              v-if="editedItem.id != -1 && viewDialog"
              v-model="viewDialog"
              max-width="1000px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">الطلب #{{ editedItem.abv }}</span>
                <v-spacer/>
                <v-icon>mdi-truck</v-icon>
                <v-btn text icon v-if="editedItem.serviceId == 4" @click="printReturnObjects">
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      تاريخ الطلب {{ $moment(editedItem.created_at).format('yyyy-MM-DD') }}
                    </v-col>
                    <v-col
                        cols="12"
                    >
                      اسم المدرسة {{
                        editedItem.building ? editedItem.building.name : 'المبنى
                            محذوف'
                      }}
                    </v-col>
                    <v-col
                        cols="12"
                    >
                      رقم التواصل {{
                        editedItem.building ? editedItem.building.contactNumber : 'المبنى
                            محذوف'
                      }}
                    </v-col>
                    <v-col
                        cols="12"
                    >
                      رقم المبنى {{
                        editedItem.building ? editedItem.building.buildingNumber : 'المبنى
                            محذوف'
                      }}
                    </v-col>
                    <v-col
                        cols="12"
                    >
                      الخدمة {{ editedItem.service ? editedItem.service.name : 'الخدمة محذوفة' }}
                    </v-col>
                    <v-col
                        v-if="editedItem.service.id == 1"
                        cols="12"
                    >
                      نوع الخدمة {{
                        editedItem.carJourneyStatus ? editedItem.carJourneyStatus : 'لا
                            يوجد'
                      }}
                    </v-col>
                    <v-row v-if="editedItem.service.id == 6">
                      <v-col
                          class="mx-3"
                          cols="12"
                      >
                        المشكلة {{ editedItem.issueType }} - {{ editedItem.issueName }}
                      </v-col>
                      <v-col
                          class="mx-3"
                          cols="12"
                      >
                        رقم العداد {{
                          editedItem.counterNumber ? editedItem.counterNumber : 'لا
                              يوجد'
                        }}
                      </v-col>
                    </v-row>
                    <v-row v-if="editedItem.service.id == 1" class="px-6">
                      <v-col
                          cols="12"
                      >
                        عدد الركاب {{ editedItem.passengerNumber }}
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        من {{ editedItem.fromLocation }}
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        الى {{ editedItem.toLocation }}
                      </v-col>

                      <v-col
                          cols="12"
                          v-if="editedItem.carServiceStartDate && editedItem.carServiceEndDate"
                      >
                        {{ editedItem.carServiceStartDate }} -> {{ editedItem.carServiceEndDate }}
                      </v-col>
                    </v-row>
                    <v-row v-if="editedItem.service.id == 4" class="px-6">
                      <v-col cols="12">
                        أنواع الأثاث
                        <v-data-table
                            :items="editedItem.return_objects"
                            :headers="returnObjectsHeaders"
                        >
                          <template v-slot:item.text="{ item }">
                            {{ item.name }}
                          </template>
                          <template v-slot:item.count="{ item }">
                            {{ item.pivot.objectsCount }}
                          </template>
                          <template v-slot:item.note="{ item }">
                            {{ item.pivot.note }}
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                    <v-row v-if="editedItem.service.id == 2 || editedItem.serviceId.id == 3"
                           class="px-6">
                      <v-col
                          cols="12"
                      >
                        وزن الصهريج
                        {{ editedItem.tankWeight }} طن
                      </v-col>
                    </v-row>
                    <v-row v-if="editedItem.serviceId == 7">
                      <newACList
                          ref="newACListRef"
                          :airConditionsProp="editedItem.air_conditions"
                          :viewMode="true"
                          :reportMode="isTechnical"/>
                    </v-row>
                    <v-row v-if="editedItem.service.id == 8" class="px-6">
                      <v-col
                          cols="12"
                      >
                        اسم المبنى {{ editedItem.permit.buildingName }}
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        اسم الشخص {{ editedItem.permit.personName }}
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        رقم السجل المدني {{ editedItem.permit.IDNumber }}
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        التاريخ {{
                          $moment(editedItem.permit.entryDateTime).format('yyy-MM-DD
                              hh:mm A')
                        }}
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        نوع التصريح {{ editedItem.permit.permitType }}
                      </v-col>
                    </v-row>
                    <v-col
                        cols="12"
                    >
                      سبب انتهاء الطلب: {{ editedItem.closeReason }}
                    </v-col>
                    <v-col
                        cols="12"
                    >
                      سبب الاغلاق القصري: {{ editedItem.forceCloseReason }}
                    </v-col>
                    <v-col
                        v-if="isEditedItemAReturnService"
                        cols="12"
                    >
                      نوع السيارة: {{ editedItem.return_object_car ? editedItem.return_object_car.name : '' }}
                    </v-col>
                    <v-col
                        v-if="isEditedItemAReturnService"
                        cols="12"
                    >
                      عدد الردود: {{ editedItem.replyCount }}
                    </v-col>
                    <v-col
                        cols="12"
                    >
                      <div v-if="editedItem.status" class="statusDiv"
                           :style="`background-color:${editedItem.status.color}`">
                        {{ editedItem.status.name }}
                      </div>
                      <div v-else style="background-color: red;color:white">
                        الحالة محذوفة
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="!isEmployee && editedItem.orderStatusId == 1 && !isGeneralManager">
                    <v-col
                        v-if="editedItem.serviceId == 1"
                        cols="12"
                    >
                      <v-select
                          v-model="editedItem.carId"
                          label="السيارة"
                          :items="cars"
                      >
                      </v-select>
                    </v-col>
                    <v-col
                        v-if="![1,6,7,8].includes(editedItem.serviceId)"
                        cols="12"
                    >
                      <v-select
                          v-model="editedItem.contractorId"
                          label="المتعهد"
                          :items="contractors"
                      >
                      </v-select>
                    </v-col>
                    <v-col
                        v-if="editedItem.serviceId == 7"
                        cols="12"
                    >
                      <v-select
                          v-model="editedItem.technicalUserId"
                          label="الفني"
                          :items="technicals"
                      >
                      </v-select>
                    </v-col>
                    <v-col
                        v-if="editedItem.serviceId == 8"
                        cols="12"
                    >
                      <v-radio-group
                          v-model="editedItem.isPermitAccepted"
                          :rules="[rules.required]"
                          @change="permitAcceptStatusChanged"
                      >
                        <v-radio
                            label="عدم الموافقة"
                            :value="0"
                        ></v-radio>
                        <v-radio
                            label="الموافقة"
                            :value="1"
                        ></v-radio>
                      </v-radio-group>
                      <v-select
                          v-model="editedItem.guardId"
                          :disabled="editedItem.isPermitAccepted == 0"
                          label="حارس الأمن"
                          :items="guards"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="editedItem.status && editedItem.status.id == 2">
                    <v-col cols="12">
                      <v-select
                          v-model="closeReason"
                          label="سبب انتهاء الطلب"
                          :rules="isServiceManager || isServicesManager? [rules.required]: []"
                          :items="finishReasons"
                      ></v-select>
                    </v-col>
                    <v-col
                        v-if="isEditedItemAReturnService"
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-select
                          v-model="editedItem.returnObjectCarId"
                          label="نوع السيارة"
                          :rules="isEmployee? [rules.required] : []"
                          :items="returnObjectCars"
                      ></v-select>
                    </v-col>
                    <v-col
                        v-if="isEditedItemAReturnService"
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-text-field label="عدد الردود"
                                    :rules="isEmployee? [rules.required] : []"
                                    v-model="editedItem.replyCount"/>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue floatedBtn"
                    text
                    @click="closeView"
                    :loading="loading"
                >
                  اغلاق
                </v-btn>
                <v-btn
                    color="blue floatedBtn"
                    text
                    :loading="loading"
                    @click="openRejectionModal(editedItem)"
                    v-if=" (editedItem.status.id == 1 && (isServicesManager || isCurrentUserAServiceManagerOnItem(editedItem) || isAdmin))"
                >
                  رفض الطلب
                </v-btn>
                <v-btn
                    color="blue floatedBtn"
                    text
                    :disabled="!allowChangeStatus"
                    :loading="loading"
                    @click="changeStatus(+editedItem.status.id + 1,editedItem.id)"
                    v-if="!isTechnical
                     && editedItem.serviceId != 8 &&
                     ((editedItem.status.id == 1 && !isEmployee) ||
                     (editedItem.status.id == 2 && (isAdmin || isEmployee)) &&
                     !isGeneralManager)"
                >
                  {{ editedItem.status.id == 1 ? 'فتح الطلب' : 'الانتهاء من الطلب' }}
                </v-btn>
                <v-btn
                    color="blue floatedBtn"
                    text
                    :loading="loading"
                    @click="sendACOrderReport"
                    v-if="canReport"
                >
                  ارسال التقرير
                </v-btn>
                <v-btn
                    color="blue floatedBtn"
                    text
                    :loading="loading"
                    @click="sendPermitAllowance"
                    v-if="canSendPermitAllowance"
                >
                  ارسال الموافقة
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                @click="viewItem(item)"
                v-bind="attrs"
                v-on="on"
            >
              mdi-eye
            </v-icon>
          </template>
          <span>عرض الطلب</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!isEmployee && !isGeneralManager && !isTechnical && item.orderStatusId == 2 ">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                @click="remindToCloseOrder(item)"
                :loading="sendingSMS"
                v-bind="attrs"
                v-on="on"
            >
              mdi-chat
            </v-icon>
          </template>
          <span>ارسال تذكير للمبنى</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!isEmployee && !isGeneralManager && !isTechnical && item.orderStatusId == 2 ">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                @click="remindContractor(item)"
                :loading="sendingSMS"
                v-bind="attrs"
                v-on="on"
            >
              mdi-send
            </v-icon>
          </template>
          <span>ارسال تذكير للمتعهد</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!isGeneralManager && ([1, 2, 5].includes(item.orderStatusId))">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
                :disabled="returnObjects.length == 0 && item.serviceId == 4"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>تعديل الطلب</span>
        </v-tooltip>
        <v-tooltip bottom v-if="isAdmin">
          <template v-slot:activator="{ on, attrs }">
            <v-icon

                small
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>حذف الطلب</span>
        </v-tooltip>
        <v-tooltip bottom
                   v-if="(isAdmin || isServicesManager || isCurrentUserAServiceManagerOnItem(item)) && item.orderStatusId == 3">
          <template v-slot:activator="{ on, attrs }">
            <v-icon

                small
                @click="downloadLetter(item)"
                v-bind="attrs"
                v-on="on"
            >
              mdi-file
            </v-icon>
          </template>
          <span>تحميل الخطاب</span>
        </v-tooltip>
        <v-tooltip bottom v-if="(isAdmin || isServicesManager || isCurrentUserAServiceManagerOnItem(item)) && item.orderStatusId != 4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                @click="openFinishOrderConfirmation(4, item.id)"
                v-bind="attrs"
                v-on="on"
            >
              mdi-close
            </v-icon>
          </template>
          <span>إغلاق الطلب</span>
        </v-tooltip>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="statusDiv"
             :style="`width:100%;background-color:${item.status.color}`">
          {{ item.status.name }}
        </div>
      </template>
    </v-data-table>
    <ReturnObjects
        v-if="editedItem.serviceId == 4 && printing"
        :order="editedItem"
        ref="returnObjectsPrintablePageRef"
    />
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import formValidation from "@/formValidation"
import newACList from "@/components/Order/AirCondition/newACList"
import ReturnObjects from "@/components/printPages/ReturnObjects"

export default {
  // {{item.status.id == 2 && item.finishCode?` و كود التسليم ${item.finishCode}`:''}}
  name: "ordersList",
  components: {newACList, ReturnObjects},
  data() {
    return {
      loading: false,
      rules: formValidation.rules,
      dialog: false,
      dialogDelete: false,
      viewDialog: false,
      fetching: true,
      sendingSMS: false,
      printing: false,
      returnObjectsHeaders: [
        {
          text: 'الاسم',
          align: 'start',
          sortable: false,
          value: 'text',
        },
        {
          text: 'العدد',
          align: 'start',
          sortable: false,
          value: 'count',
        },
        {
          text: 'ملاحظات',
          align: 'start',
          sortable: false,
          value: 'note',
        }
      ],
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'abv',
        },
        {
          text: 'المبنى',
          align: 'start',
          sortable: false,
          value: 'building.name',
        },
        {
          text: 'رقم المبنى',
          align: 'start',
          sortable: false,
          value: 'building.buildingNumber',
        },
        {
          text: 'المكتب',
          align: 'start',
          sortable: false,
          value: 'building.location',
        },
        {
          text: 'الخدمة',
          align: 'start',
          sortable: false,
          value: 'service.name',
        },
        {
          text: 'سبب الرفض',
          align: 'start',
          sortable: false,
          value: 'rejection_reason.name'
        },
        {
          text: 'الحالة',
          align: 'start',
          sortable: false,
          value: 'status',
          width: '30%'
        },
        {
          text: 'أدوات',
          align: 'start',
          sortable: false,
          value: 'actions',
        }
      ],
      orders: [],
      services: [],
      buildings: [],
      returnObjects: [],
      cars: [],
      contractors: [],
      technicals: [],
      guards: [],
      returnObjectCars: [],
      rejectionReasons: [],
      finishReasons: [],
      closingReasons: [],
      editedIndex: -1,
      filters: {
        q: ''
      },
      totalModels: -1,
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      editedItem: {
        id: -1,
        carId: null,
        contractorId: null,
        technicalUserId: null,
        isReportFinished: false,
        isPermitAccepted: false,
        buildingId: '',
        serviceId: '',
        guardId: null,
        carServiceStartDate: '',
        carServiceEndDate: '',
        passengerNumber: '',
        fromLocation: '',
        toLocation: '',
        returnObjects: [],
        carJourneyStatus: null,
        counterNumber: null,
        issueType: null,
        tankWeight: null,
        issueName: 'فصل الخدمة',
        closeReason: '',
        forceCloseReason: '',
        returnObjectCarId: null,
        replyCount: null,
        permit: {
          id: -1,
          buildingName: '',
          personName: '',
          IDNumber: '',
          entryDateTime: null,
          permitType: null
        }
      },
      defaultItem: {
        id: -1,
        carId: null,
        contractorId: null,
        technicalUserId: null,
        isReportFinished: false,
        isPermitAccepted: false,
        buildingId: '',
        serviceId: '',
        guardId: null,
        carServiceStartDate: '',
        carServiceEndDate: '',
        passengerNumber: '',
        fromLocation: '',
        toLocation: '',
        returnObjectId: '',
        otherReturnObject: '',
        returnObjectCount: '',
        carJourneyStatus: null,
        counterNumber: null,
        issueType: null,
        tankWeight: null,
        issueName: 'فصل الخدمة',
        closeReason: '',
        forceCloseReason: '',
        returnObjectCarId: null,
        replyCount: null,
        permit: {
          id: -1,
          buildingName: '',
          personName: '',
          IDNumber: '',
          entryDateTime: null,
          permitType: null
        }
      },
      closeReason: null,
      viewRejectionDialog: false,
      orderForRejection :{
        currentRejectionReasonId: null
      },
      closeOrderConfirmationObject: {
        statusId: -1,
        orderId: -1,
        showConfirmDialog: false,
        showCloseConfirmDialog: false
      }
    }
  },

  computed: {
    ...mapGetters([
      'loggedUser',
      'isAdmin',
      'isEmployee',
      'isServiceManager',
      'isSchoolBuilding',
      'isServicesManager',
      'isGeneralManager',
      'isTechnical'
    ]),
    formTitle() {
      return this.editedIndex === -1 ? 'طلب جديد' : 'تعديل طلب'
    },
    canReport() {
      const {orderStatusId, serviceId, technicalUserId, isReportFinished} = this.editedItem
      return orderStatusId == 2 && serviceId == 7 && technicalUserId == this.loggedUser.id && isReportFinished != 1
    },
    canSendPermitAllowance() {
      const {orderStatusId, serviceId, guardId, isPermitAccepted} = this.editedItem
      return !this.isEmployee && orderStatusId == 1 && serviceId == 8 && ((!guardId && isPermitAccepted == 0) || (guardId && isPermitAccepted == 1))
    },
    allowChangeStatus() {

      const {contractorId, carId, orderStatusId, serviceId, technicalUserId, isReportFinished} = this.editedItem
      if (orderStatusId == 2 && this.isEditedItemAReturnService) {
        if(this.isEmployee)  return (/*this.editedItem.returnObjectCarId &&*/ this.editedItem.replyCount != null && this.editedItem.replyCount != '');
        else{
          return (this.closeReason != null)
        }
      }
      if (orderStatusId != 1 && serviceId != 7) return true
      else {
        return serviceId == 6 ||
            (serviceId == 1 && carId) ||
            (serviceId == 7 && orderStatusId == 1 && technicalUserId) ||
            (serviceId == 7 && orderStatusId == 2 && isReportFinished == 1) ||
            (serviceId != 1 && serviceId != 7 && contractorId)
      }
    },
    airConditions() {
      return this.$refs.newACListRef.$data.airConditions
    },
    isEditedItemAReturnService() {
      if (!this.editedItem) return false
      return [4, 15].includes(this.editedItem.serviceId)
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    pagination: {
      deep: true,

      handler: function () {
        this.getOrders()
      }
    }
  },
  methods: {
    isCurrentUserAServiceManagerOnItem(order) {
      if (!order) return false
      if (!this.isServiceManager) return false
      return this.loggedUser.services.find(s => s.id == order.serviceId)
    },
    printReturnObjects() {
      this.printing = true
      this.$nextTick(() => {
        const returnObjectsHTML = this.$refs.returnObjectsPrintablePageRef.$el
        let printWindow = window.open('')
        printWindow.document.body.style = returnObjectsHTML.style
        printWindow.document.write('<html><head><title></title><link rel="stylesheet" href="returnObjectsPrintablePage.css"></head><body>');
        printWindow.document.write(returnObjectsHTML.outerHTML)
        printWindow.document.write('</body></html>')
        setTimeout(() => {
          printWindow.print()
          printWindow.close()
          this.printing = false
        }, 1000)
      })

    },
    editItem(item) {
      this.editedIndex = this.orders.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (this.editedItem.serviceId == 4) {
        this.editedItem.returnObjects = this.editedItem.return_objects.map(ro => {
          let originalReturnObject = this.returnObjects.find(r => r.value == ro.id)
          if (originalReturnObject) {
            originalReturnObject.count = ro.pivot.objectsCount
            originalReturnObject.note = ro.pivot.note
          }
          return originalReturnObject
        }).filter(r => r)
      }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.orders.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async remindToCloseOrder(item) {
      try {
        this.sendingSMS = true
        await this.$getRequest(`order`, `remindToCloseOrder/${item.id}`)
      } catch (error) {

      } finally {
        this.sendingSMS = false
      }
    },
    async remindContractor(item) {
      try {
        this.sendingSMS = true
        await this.$getRequest(`order`, `remindContractor/${item.id}`)
      } catch (error) {

      } finally {
        this.sendingSMS = false
      }
    },
    async viewItem(item) {
      this.editedIndex = this.orders.indexOf(item)
      this.editedItem = Object.assign({}, item)
      await this.getContractors()
      this.viewDialog = true
    },
    openRejectionModal(item){
      this.orderForRejection = item
      this.orderForRejection.currentRejectionReasonId = null
      this.viewRejectionDialog = true

    },
    async deleteItemConfirm() {
      await this.$deleteRequest('order', this.editedItem.id)
      this.orders.splice(this.editedIndex, 1)
      this.closeDelete()
    },
    search() {
      this.getOrders()
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeView() {
      this.viewDialog = false
      this.closeOrderConfirmationObject = {
        statusId: -1,
        orderId: -1,
        showConfirmDialog: false,
        showCloseConfirmDialog: false,
      }
      this.viewRejectionDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.orderForRejection = {
          currentRejectionReasonId: null
        }
      })
    },

    verifySelectedReturnObjects(objects) {
      return objects.every(o => o.count)
    },
    permitAcceptStatusChanged() {
      if (!this.editedItem.isPermitAccepted) {
        this.editedItem.guardId = null
      }
    },
    async save() {
      try {
        let {
          serviceId,
          returnObjectId,
          returnObjects,
          otherReturnObject,
          returnObjectCount,
          carServiceStartDate,
          carServiceEndDate,
          passengerNumber,
          fromLocation,
          toLocation,
          buildingId,
          carJourneyStatus,
          counterNumber,
          issueType,
          issueName,
          tankWeight,
          carId,
          contractorId,
          permit
        } = this.editedItem
        if (this.$refs.orderForm.validate()) {
          this.loading = true
          buildingId = this.isEmployee ? undefined : buildingId
          returnObjectId = serviceId != 4 ? null : returnObjectId
          returnObjects = serviceId != 4 ? [] : returnObjects
          returnObjectCount = serviceId != 4 ? null : returnObjectCount
          otherReturnObject = serviceId == 4 && returnObjectId == null ? otherReturnObject : null
          passengerNumber = serviceId != 1 ? null : passengerNumber
          fromLocation = serviceId != 1 ? null : fromLocation
          toLocation = serviceId != 1 ? null : toLocation
          carServiceStartDate = serviceId != 1 ? null : carServiceStartDate
          carServiceEndDate = serviceId != 1 ? null : carServiceEndDate
          carJourneyStatus = serviceId != 1 ? null : carJourneyStatus
          counterNumber = serviceId != 6 ? null : counterNumber
          issueType = serviceId != 6 ? null : issueType
          issueName = serviceId != 6 ? null : issueName
          tankWeight = serviceId != 2 && serviceId != 3 ? null : tankWeight
          permit = serviceId != 8 ? undefined : permit
          let airCondition = []
          if (this.$refs.newACListRef)
            airCondition = this.$refs.newACListRef.$data.airConditions

          const form = {
            buildingId,
            serviceId,
            returnObjectId,
            returnObjects,
            otherReturnObject,
            passengerNumber,
            fromLocation,
            toLocation,
            carServiceStartDate,
            carServiceEndDate,
            returnObjectCount,
            carJourneyStatus,
            counterNumber,
            issueType,
            issueName,
            tankWeight,
            carId,
            contractorId,
            airCondition,
            permit
          }

          try {
            if (this.verifySelectedReturnObjects(returnObjects)) {
              returnObjects = returnObjects.map(o => {
                return {
                  ...o,
                  note: o.note ? o.note : ''
                }
              })
              this.loading = true
              if (this.editedItem.id == -1) {
                await this.$postRequest('order', '', form)
                this.getOrders()
              } else {
                await this.$putRequest('order', this.editedItem.id, form)
                this.getOrders()
              }
              this.notify({textType: this.snackBarTextType.saveSuccessfully});
              this.close()
            } else {
              this.notify({text: 'حقل العدد اجباري'});
            }
          } catch (error) {
            console.log(error)
            if (error.response.status == 400) {
              this.notify({text: error.response.data.msg});
            } else {
              this.notify({textType: this.snackBarTextType.saveError});
            }
          } finally {
            this.loading = false
          }
        }
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async getOrders() {
      try {
        this.loading = true
        let orders = await this.$getRequest('order', '', {
          me: this.isEmployee ? true : undefined,
          meAsTechnical: this.isTechnical ? true : undefined,
          serviceIds: !this.isAdmin && !this.isEmployee ? this.loggedUser.services.map(s => s.id) : undefined,
          q: this.filters.q,
          page: this.pagination.page,
          pageSize: this.pagination.itemsPerPage,
        })
        this.orders = orders.data
        this.totalModels = orders.total
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async sendACOrderReport() {
      try {
        this.loading = true
        await this.$postRequest('order', 'reportACOrder', {
          airConditions: this.airConditions,
          orderId: this.editedItem.id
        })
        this.closeView()
        this.getOrders()
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async sendPermitAllowance() {
      try {
        this.loading = true
        await this.$postRequest('order', 'storePermitAllowance', {
          isPermitAccepted: this.editedItem.isPermitAccepted,
          guardId: this.editedItem.guardId,
          orderId: this.editedItem.id
        })
        this.closeView()
        this.getOrders()
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    openCloseOrderConfirmation(statusId, orderId) {
      this.closeOrderConfirmationObject = {
        statusId,
        orderId,
        showConfirmDialog: true,
        showCloseConfirmDialog: false,
      }
    },
    openFinishOrderConfirmation(statusId, orderId) {
      this.editedItem.forceCloseReason = ''
      this.closeOrderConfirmationObject = {
        statusId,
        orderId,
        showConfirmDialog: false,
        showCloseConfirmDialog: true
      }
    },
    async changeStatus(newStatusId, orderId) {
      try {
        if (newStatusId == 3 && this.isEmployee) {
          this.openCloseOrderConfirmation(newStatusId, orderId)
          return
        }
        await this.callChangeStatus(newStatusId, orderId)
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async callChangeStatus(newStatusId, orderId) {
      try {
        this.loading = true
        await this.$putRequest('order', `changeStatus/${orderId}`, {
          newStatusId,
          replyCount: this.editedItem.replyCount,
          closeReason: !this.closeReason ? '' : this.closeReason,
          forceCloseReason: !this.editedItem.forceCloseReason ? '' : this.editedItem.forceCloseReason,
          returnObjectCarId: this.editedItem.returnObjectCarId,
          carId: this.editedItem.carId,
          contractorId: this.editedItem.contractorId,
          technicalUserId: this.editedItem.technicalUserId,
          rejectReasonId: this.orderForRejection.currentRejectionReasonId
        })
        this.editedItem.forceCloseReason = ''
        this.closeView()
        this.getOrders()
        this.notify({textType: this.snackBarTextType.saveSuccessfully})
      } catch (error) {
        this.notify({textType: this.snackBarTextType.saveError})
      } finally {
        this.loading = false
      }
    },
    async checkCanStoreNewOrderAndOpenNewOrder({
                                                 serviceId
                                               }) {
      try {
        // if (this.isEmployee) await this.$getRequest('order', 'checkCanStoreNewOrder')
        if (serviceId) {
          this.editedItem.serviceId = serviceId
        }
        this.dialog = true
      } catch (error) {
        if (error.response.status == 400) {
          this.text =
              this.notify({text: error.response.data.msg});
        }

      }
    },
    async getServices() {
      let services = await this.$getRequest('service', '')
      this.services = services.filter(s => !this.isEmployee && !this.isAdmin ?
          this.loggedUser.services.some(lus => lus.id == s.id) :
          (this.isSchoolBuilding ? s.id != 1 : true)).map(s => {
        return {
          text: s.name,
          value: s.id,
          isAvailable: s.isAvailable
        }
      })
    },
    async getBuildings() {
      let buildings = await this.$getRequest('building', '')
      this.buildings = buildings.map(s => {
        return {
          text: s.name,
          value: s.id
        }
      })
    },
    async getReturnObjects() {
      let returnObjects = await this.$getRequest('returnObject', '')
      this.returnObjects = returnObjects.map(r => {
        return {
          text: r.name,
          value: r.id
        }
      })
    },
    async getContractors() {
      let contractors = await this.$getRequest('contractor', '', {
        serviceId: this.editedItem.serviceId
      })
      this.contractors = contractors.map(s => {
        return {
          text: s.contractorName,
          value: s.id
        }
      })
    },
    async getTechnicals() {
      let technicals = await this.$getRequest('users', '', {
        roleId: 7500
      })
      this.technicals = technicals.map(s => {
        return {
          text: s.username,
          value: s.id
        }
      })
    },
    async getCars() {
      let cars = await this.$getRequest('car', '')
      this.cars = cars.map(s => {
        return {
          text: `${s.panelNumber} | ${s.driverName}`,
          value: s.id
        }
      })
    },
    async getGuards() {
      try {
        this.loading = true
        let guards = await this.$getRequest('guard', '')
        this.guards = guards.map(g => {
          return {
            text: g.name,
            value: g.id
          }
        })
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    newNotification(notification) {
      let orderIndex = this.orders.findIndex(o => o.id == notification.order.id)
      if (orderIndex != -1) {
        this.orders.splice(orderIndex, 1, notification.order)
      }
    },
    newOrder(notification) {
      this.orders.unshift(notification.order)
    },
    downloadLetter({id}) {
      window.open(`${this.$baseUrl}downloadLetter/${id}`)
    },
    async getReturnObjectCars() {
      try {
        this.loading = true
        let returnObjectCars = await this.$getRequest('returnObjectCars', '')
        this.returnObjectCars = returnObjectCars.map(g => {
          return {
            text: g.name,
            value: g.id
          }
        })
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async getRejectionReasons() {
      try {
        this.loading = true
        let rejectionReasons = await this.$getRequest('orderRejectionReasons', '')
        this.rejectionReasons = rejectionReasons.map(g => {
          return {
            text: g.name,
            value: g.id
          }
        })
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async getFinishReasons() {
      try {
        this.loading = true
        let finishReasons = await this.$getRequest('orderFinishReasons', '')
        this.finishReasons = finishReasons.map(g => {
          return {
            text: g.name,
            value: g.name
          }
        })
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async getClosingReasons() {
      try {
        this.loading = true
        let closingReasons = await this.$getRequest('orderClosingReasons', '')
        this.closingReasons = closingReasons.map(g => {
          return {
            text: g.name,
            value: g.name
          }
        })
      } catch (error) {
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    this.pusherChannel = this.$pusher.subscribe('notifications')
    this.pusherChannel.bind(`notifications.orderStatusChanged.${this.loggedUser.id}`, this.newNotification);
    if (!this.isEmployee) {
      if (this.isAdmin) {
        this.pusherChannel.bind(`notifications.newOrder.all'}`, this.newOrder);
      } else {
        if (!this.isTechnical)
          this.loggedUser.services.forEach(service => {
            this.pusherChannel.bind(`notifications.newOrder.${service.id}`, this.newOrder);
          })
        else
          this.loggedUser.services.forEach(service => {
            this.pusherChannel.bind(`notifications.newOrder.7`, this.newOrder);
          })
      }
    }
    this.getOrders()
    this.getServices()
    this.getBuildings()
    this.getReturnObjects()
    this.getContractors()
    this.getCars()
    this.getTechnicals()
    this.getGuards()
    this.getReturnObjectCars()
    this.getRejectionReasons()
    this.getFinishReasons()
    this.getClosingReasons()
  }
}
</script>

<style scoped>
@media print {
  .table {
    background-color: red;
  }
}

.table {
  background-color: red;
}
</style>

